.article-container {
  margin-left: 20px;
}

@media (max-width: 992px) {
  .article-container {
    margin-left: 0px;
    margin-top: 38px;
  }  
}


.article-body {
  margin-bottom: 32px;
  padding: 36px;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  font-family: rubikMedium, sans-serif;
}

.article-header {
  padding: 18px 0;
  border-bottom: 1px solid #F0F0F0;

  .a-title {
    color: #344563;
    font-size: 28px;
  }

  .a-info {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
    font-family: robotoRegular, sans-serif;
    .a-from {
      padding-right: 6px;
      color: #696974;
    }

    .a-author {
      padding-right: 10px;
      border-right: 1px solid #696974;
      color: #FF570D;
      text-decoration: underline;
    }

    .a-time {
      padding-left: 10px;
      color: #696974;
    }
  }
}

.article-image {
  padding: 36px 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 0px;

  .a-img {
    img {
      width: 100%;
      border-radius: 10px;
      height: 290px;
    }
  }

  .a-img-name {
    padding-top: 21px;
    font-size: 13px;
    color: #B5B5BE;
    font-family: robotoRegular, sans-serif;
    padding-bottom: 24px;
  }
}

.article-content {
  padding: 18px 0;
}

.content-more {
  text-align: center;
  margin-top: 20px;
}

.article-btn-more {
  background: white;
  border: 1px solid #E6E6E7;
  border-radius: 5px;
  min-width: 170px;
  width: 170px;
  height: 38px;
  font-size: 14px;
  font-family: rubikMedium, sans-serif;
  color: #44444F;
  font-weight: 100;
}