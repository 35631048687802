.inputWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.inputLabel {
  flex: 2;
  padding-right: 5px;
  font-size: 14px;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #6a6a6a50;
  padding: 3px;
  height: 34px;
  flex: 9;
  font-size: 14px;
  padding: 5px 10px;
  outline: none;
}
.input:focus {
  border-bottom: 0.5px solid rgb(59, 59, 59);
}

.formCheckbox {
  display: flex;
  flex: 9;
}

.marginLeft10 {
  margin-left: 25px;
}

.paddingLeft35 {
  padding-left: 35px !important;
}

.selectDropdownIcon {
  -webkit-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='8px' height='6px' viewBox='0 0 8 6' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg'%3E%3Cdesc%3ECreated with Lunacy%3C/desc%3E%3Cpath d='M1.03496 0L6.96506 0C7.53664 -3.91989e-15 8 0.493987 8 1.10335C8 1.36147 7.91512 1.61141 7.76012 1.8097L4.79507 5.60297C4.42916 6.0711 3.77656 6.13434 3.33746 5.74424C3.28938 5.70152 3.24502 5.65423 3.20495 5.60297L0.239902 1.8097C-0.126014 1.34157 -0.0666874 0.645839 0.372412 0.255733C0.558406 0.0904927 0.792853 0 1.03496 0Z' id='IconDropdown' fill='%2392929D' fill-rule='evenodd' stroke='none' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 15px;
}

.flex2dot6 {
  flex: 2.6;
}

.flex3dot8 {
  flex: 3.8;
  width: 50%;
}

.flex0dot2 {
  flex: 0.2;
}

.labelContainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 0 15px 25px 0;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.labelContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.labelContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

.labelContainer input:checked ~ .checkmark {
  background-color: #FE5810;
}

.labelContainer input:checked ~ .checkmark:after {
  display: block;
}

.labelContainer .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid #B5B5BE;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
