[data-slate-editor] > blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

[data-slate-editor] > blockquote[dir='rtl'] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

[data-slate-editor] > ul {
  list-style-type: disc !important;
  padding-left: 40px !important;
  margin: 0px;
}

[data-slate-editor] > p {
  margin-bottom: 0 !important;
}