@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?6o5gpc');
  src:  url('./fonts/icomoon.eot?6o5gpc#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?6o5gpc') format('truetype'),
    url('./fonts/icomoon.woff?6o5gpc') format('woff'),
    url('./fonts/icomoon.svg?6o5gpc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-park-tickets:before {
  content: "\e934";
}
.icon-like:before {
  content: "\e923";
}
.icon-share:before {
  content: "\e92c";
}
.icon-bus:before {
  content: "\e91e";
}
.icon-car:before {
  content: "\e920";
}
.icon-cart:before {
  content: "\e921";
}
.icon-comment-large:before {
  content: "\e922";
}
.icon-computer:before {
  content: "\e924";
}
.icon-diagram-right:before {
  content: "\e925";
}
.icon-direction:before {
  content: "\e926";
}
.icon-food:before {
  content: "\e927";
}
.icon-health:before {
  content: "\e928";
}
.icon-home:before {
  content: "\e929";
}
.icon-hotel-building:before {
  content: "\e92a";
}
.icon-lifestyle:before {
  content: "\e92b";
}
.icon-location:before {
  content: "\e92d";
}
.icon-message:before {
  content: "\e92e";
}
.icon-mobile:before {
  content: "\e92f";
}
.icon-news:before {
  content: "\e930";
}
.icon-noun-job:before {
  content: "\e931";
}
.icon-politics:before {
  content: "\e932";
}
.icon-premium:before {
  content: "\e933";
}
.icon-sports:before {
  content: "\e935";
}
.icon-tech:before {
  content: "\e936";
}
.icon-video:before {
  content: "\e937";
}
.icon-weather-bright:before {
  content: "\e938";
}
.icon-weather-normal:before {
  content: "\e939";
}
.icon-weather-rain-storms:before {
  content: "\e93a";
}
.icon-weather-rain:before {
  content: "\e93b";
}
.icon-forward:before {
  content: "\e905";
}
.icon-dropbox:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-google-drive:before {
  content: "\e908";
}
.icon-google:before {
  content: "\e909";
}
.icon-happiness:before {
  content: "\e90a";
}
.icon-images:before {
  content: "\e90b";
}
.icon-inbox1:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90f";
}
.icon-text-box:before {
  content: "\e912";
}
.icon-twiter:before {
  content: "\e91c";
}
.icon-archive:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-attachment:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-happy:before {
  content: "\e90d";
}
.icon-headphone:before {
  content: "\e90e";
}
.icon-important:before {
  content: "\e910";
}
.icon-inbox:before {
  content: "\e911";
}
.icon-loadmore:before {
  content: "\e913";
}
.icon-more:before {
  content: "\e914";
}
.icon-new:before {
  content: "\e915";
}
.icon-notification:before {
  content: "\e916";
}
.icon-refresh:before {
  content: "\e917";
}
.icon-reply:before {
  content: "\e918";
}
.icon-search:before {
  content: "\e919";
}
.icon-security:before {
  content: "\e91a";
}
.icon-sent:before {
  content: "\e91b";
}
.icon-spam:before {
  content: "\1f35e";
}
.icon-star:before {
  content: "\e91d";
}
.icon-trash:before {
  content: "\e91f";
}