// ======================================================
// Layout :: Email settings sidebar
// ======================================================

.sidebar {
  position: relative;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.sidebar .side-footer-text {
  font-size: 12px;
  color: #92929d;
  margin-left: 18px;
  line-height: 2.5;
}

.sidebar .side-footer {
  height: 230px;
  padding-top: 10px;
  position: relative;
  transition: 0.3s;
}

.sidebar .side-footer:after {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  right: 0;
  margin-left: 5%;
  background: #f5f5f7;
  transition: 0.3s;
  z-index: -1;
}

.sidebar .side-footer-text-copy-right {
  font-size: 12px;
  color: #c8c8cd;
  margin-left: 18px;
  line-height: 2.5;
}

.sidebar .show-sidebar {
  display: none;
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  background: url("./../../assets/images/touch_arrow.png") no-repeat center
      center/70%,
    #fff;
  left: -30px;
  top: 80px;
  -moz-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
  -webkit-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
  -ms-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
  -o-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
  box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.sidebar.show .show-sidebar {
  top: 32px;
  transform: rotate(180deg);
}

.mail-wrap .sidebar {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1000;
}

.mail-wrap .sidebar .sidebar-item {
  padding: 25px 20px;
}

.mail-wrap .sidebar .sidebar-item + .sidebar-item {
  border-top: 1px solid #eeeff3;
}

.mail-wrap .sidebar [class*="icon-"] {
  font-size: 20px;
}

.mail-wrap .sidebar .action-new .btn-new {
  position: relative;
  background: #fff;
  color: #344563;
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: center;
  display: inherit;
  transition: 0.5s;
  border-top-left-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -ms-transition: 0s;
  -o-transition: 0s;
  transition: 0.3s;
  height: 42px;
}

.mail-wrap .sidebar-open .action-new .btn-new {
  border-radius: 10px;
}

.mail-wrap .sidebar .action-new .btn-new-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  transition: 0.5s;
  border-radius: 10px;
  -moz-box-shadow: 0px 4px 10px 0px rgba(196, 203, 216, 0.45);
  -webkit-box-shadow: 0px 4px 10px 0px rgba(196, 203, 216, 0.45);
  -ms-box-shadow: 0px 4px 10px 0px rgba(196, 203, 216, 0.45);
  -o-box-shadow: 0px 4px 10px 0px rgba(196, 203, 216, 0.45);
  box-shadow: 0px 4px 10px 0px rgba(196, 203, 216, 0.45);
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -ms-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  height: 42px;
  background-color: #fafafa;
}

.mail-wrap .sidebar-open .action-new .btn-new-content {
  border-radius: 10px;
}

.mail-wrap .sidebar .action-new .btn-new-content .refresh-icon {
  padding: 0 12px;
}

.mail-wrap .sidebar .action-new .btn-new:before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
  background: rgba(254, 88, 16, 0.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .sidebar .action-new .btn-new [class*="icon-"] {
  display: inline-block;
  vertical-align: text-bottom;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  float: left;
}

.mail-wrap .sidebar .action-new .btn-new span {
  margin-left: 14px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  transform: scaleX(1);
  visibility: visible;
  margin-top: 2px;
}

.mail-wrap .sidebar .action-new .btn-new:hover {
  color: #fe5810;
}

.mail-wrap .sidebar .action-new .btn-new:hover:before {
  width: 100%;
}

.mail-wrap .sidebar .list-action li + li {
  margin-top: 10px;
}

.mail-wrap .sidebar .list-action li a {
  position: relative;
  padding: 8px 10px;
  color: #b5b5be;
  text-transform: capitalize;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  z-index: 1;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -ms-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.mail-wrap .sidebar .list-action li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  // border-radius: 8px;
  background: #fafafa;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
}

.mail-wrap .sidebar .list-action li a [class*="icon-"] {
  // width: 30px;
  text-align: center;
  display: block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .sidebar .list-action li a span {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  transform: scaleX(1);
  visibility: visible;
}

.mail-wrap .sidebar .list-action li a span.txt-action {
  margin-left: 10px;
  white-space: nowrap;
}

.mail-wrap .sidebar .list-action li a span.txt-lable {
  margin-left: 48px;
  position: relative;
}

.mail-wrap .sidebar .list-action li a span.txt-lable:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ffc542;
  border-radius: 50%;
  bottom: 7px;
  left: -20px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .sidebar .list-action li a span.txt-lable {
  &.txt-orange:after {
    background: #ff570d;
  }
  &.txt-green:after {
    background: #57c07d;
  }
  &.txt-blue:after {
    background: #00b6ff;
  }
  &.txt-pink:after {
    background: #bc2561;
  }
}

.mail-wrap .sidebar .list-action li a.active span {
  &.txt-orange {
    color: #ff570d;
  }
  &.txt-green {
    color: #57c07d;
  }
  &.txt-blue {
    color: #00b6ff;
  }
  &.txt-pink {
    color: #bc2561;
  }
}

.mail-wrap .sidebar .list-action li a.new-labe svg path {
  transition: 0.3s;
  fill: #ff570d;
}
.mail-wrap .sidebar .list-action li a.new-labe span {
  color: #ff570d;
}

.mail-wrap .sidebar .list-action li a .numb {
  margin-left: auto;
  white-space: nowrap;
}

.mail-wrap .sidebar .list-action li a:hover svg path,
.mail-wrap .sidebar .list-action li a:hover,
.mail-wrap .sidebar .list-action li a.active,
.mail-wrap .sidebar .list-action li a.active svg path {
  transition: 0.3s;
  fill: #fc5a5a;
  color: #fc5a5a;
  text-decoration: none;
}

.mail-wrap .sidebar .list-action li a svg path {
  transition: 0.3s;
  fill: #b5b5be;
}

.mail-wrap .sidebar .list-action li a.btn-down-up {
  .icon-content {
    display: inline-flex;
  }
  .txt-action {
    position: relative;
  }
  .txt-action:after {
    content: "";
    position: absolute;
    width: 130px;
    height: 1px;
    background: #f1f1f5;
    border-radius: 50%;
    top: 11px;
    margin-left: 10px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
}
.mail-wrap .sidebar .list-action li a {
  .icon-content {
    display: inline-flex;
  }
}

.mail-wrap .sidebar .list-action li a:hover:after,
.mail-wrap .sidebar .list-action li a.active:after {
  width: 100%;
}

.mail-wrap .sidebar .list-action li a.active {
  font-family: rubikMedium, sans-serif;
}

.mail-wrap .sidebar .list-action li.has-note a [class*="icon-"] {
  position: relative;
}

.mail-wrap .sidebar .list-action li.has-note a [class*="icon-"]:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ffc542;
  border-radius: 50%;
  top: -3px;
  right: 3px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.content-wrap .sidebar {
  background: #fff;
}

.content-wrap .sidebar .sidebar-item + .sidebar-item {
  margin-top: 30px;
}

.content-wrap .sidebar .sidebar-title {
  color: #171725;
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.content-wrap .sidebar .sidebar-title h3 {
  margin: 0;
  font-size: 14px;
}

.content-wrap .sidebar .sidebar-title.box-flex {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 20px;
}

.content-wrap .sidebar .sidebar-title.box-flex .dropdown {
  margin-left: 10px;
}

.content-wrap .sidebar .sidebar-title.box-flex .dropdown .dropdown-toggle {
  background: #f1f1f5;
  min-width: 90px;
}

.content-wrap .sidebar .list-tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0;
}

.content-wrap .sidebar .list-tag li {
  width: calc((100% - 10px) / 2);
}

.content-wrap .sidebar .list-tag li:nth-child(2n) {
  margin-left: 10px;
}

.content-wrap .sidebar .list-tag li:nth-child(n + 3) {
  margin-top: 10px;
}

.content-wrap .sidebar .list-tag li a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-wrap .sidebar .ads a {
  display: block;
  padding-top: 59.21569%;
  background-size: 100%;
}

.content-wrap .sidebar .ads a:hover {
  opacity: 0.8;
}

.content-wrap .sidebar .dropdown {
  font-size: 12px;
}

.content-wrap .sidebar .dropdown .dropdown-toggle {
  border: 1px solid #e6e6ea;
  padding: 5px 20px 3px 10px;
  border-radius: 5px;
}

.content-wrap .sidebar .dropdown .dropdown-toggle:after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.content-wrap .sidebar .dropdown .dropdown-toggle:not(.flag) {
  display: block;
}

.content-wrap .sidebar .dropdown .dropdown-toggle.flag .flag-image {
  width: 16px;
  height: 12px;
}

.content-wrap .sidebar .dropdown .dropdown-toggle:hover {
  text-decoration: none;
  border-color: #fe5810;
}

.content-wrap .sidebar .dropdown .dropdown-menu {
  width: 100%;
  min-width: 0;
}

.content-wrap .sidebar .dropdown .dropdown-menu li a {
  font-size: 12px;
}

.content-wrap .sidebar .list-select {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 12px;
}

.content-wrap .sidebar .list-select a {
  text-decoration: none;
}

.content-wrap .sidebar .list-select > li {
  width: calc(100% - 90px);
}

.content-wrap .sidebar .list-select > li + li {
  margin-left: 10px;
}

.content-wrap .sidebar .list-select > li:first-child {
  width: 80px;
}

.content-wrap .sidebar .list-country-currency {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0;
}

.content-wrap .sidebar .list-country-currency .country-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  padding: 9px 10px;
}

.content-wrap .sidebar .list-country-currency .country-item:nth-child(2n + 1) {
  background: #fafafb;
}

.content-wrap .sidebar .list-country-currency .country-item .flag {
  flex: auto;
}

.content-wrap .sidebar .list-country-currency .country-item .currency-key {
  margin-left: 7px;
}

.content-wrap .sidebar .list-country-currency .country-item .currency-numb {
  margin-left: 7px;
  min-width: 75px;
  white-space: nowrap;
  text-align: right;
}

.content-wrap .sidebar .sidebar-button {
  margin-top: 18px;
}

.content-wrap .sidebar .sidebar-button .btn {
  width: 100%;
  display: block;
  text-transform: capitalize;
  font-size: 13px;
}

.content-wrap .sidebar .sidebar-button .btn.btn-primary {
  background: transparent;
  color: #fe5810;
}

.content-wrap .sidebar .sidebar-button .btn.btn-primary:hover {
  background: #fe5810;
  color: #fff;
  border-color: #fe5810;
}

.content-wrap .sidebar .score-list .flag .flag-image {
  width: 24px;
  height: 24px;
}

.content-wrap .sidebar .score-list .score-items + .score-items {
  margin-top: 15px;
}

.content-wrap .sidebar .score-list .score-items .score-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.content-wrap .sidebar .score-list .score-items .score-item + .score-item {
  margin-top: 6px;
}

.content-wrap .sidebar .score-list .score-items .score-item .flag {
  width: 45%;
}

.content-wrap .sidebar .score-list .score-items .score-item .score-numb {
  width: 25px;
  margin: 0 10px;
}

.content-wrap .sidebar .score-list .score-items .score-item .score-time,
.content-wrap .sidebar .score-list .score-items .score-item .score-address {
  width: calc(55% - 45px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-wrap .sidebar .score-list .score-items .score-item .score-time {
  color: #ffd168;
}

.content-wrap .sidebar .weather-list {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  text-align: center;
}

.content-wrap .sidebar .weather-list span {
  display: block;
}

.content-wrap .sidebar .weather-list [class*="icon-"] {
  font-size: 28px;
  color: #ffc542;
  margin-bottom: 16px;
}

.content-wrap .sidebar .weather-list .weather-txt {
  color: #171725;
  margin-bottom: 2px;
}

.content-wrap .sidebar .weather-list .weather-txt .weather-temperature {
  font-size: 13px;
}

.content-wrap .sidebar .video-items .video-item + .video-item {
  margin-top: 15px;
}

.content-wrap .sidebar .video-items .video-item .thumbnail {
  width: 105px;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.content-wrap .sidebar .video-items .video-item .thumbnail a {
  position: relative;
  display: block;
  height: 80px;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.content-wrap .sidebar .video-items .video-item .thumbnail a:after {
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  background: url("./../../assets/images/play_img.png") no-repeat center center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrap .sidebar .video-items .video-item .thumbnail a:hover {
  opacity: 0.8;
}

.content-wrap .sidebar .video-items .video-item .video-info {
  width: calc(100% - 115px);
}

.content-wrap .sidebar .video-items .video-item .video-info h4 {
  font-size: 13px;
  margin-bottom: 3px;
  line-height: 1.4;
}

.content-wrap .sidebar .video-items .video-item .video-info h4 a {
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.content-wrap .sidebar .video-items .video-item .video-info h4 a:not(:hover) {
  color: #696974;
}

.content-wrap .sidebar .video-items .video-item .video-info .txt-meta {
  font-size: 12px;
}

.content-wrap .sidebar .radio-run {
  width: 255px;
  height: 56px;
  background: #fafafb;
  border-radius: 8px 8px 0 0;
  display: inline-block;
  padding: 0 14px;

  &-image {
    display: inline-block;
    img {
      width: 30px;
      height: 30px;
    }
  }

  &-info {
    display: inline-block;
    padding: 5px;
  }

  &-name {
    font-size: 14px;
    font-family: rubikRegular, sans-serif;
  }

  &-type {
    color: #92929d;
    font-size: 11px;
    font-family: rubikRegular, sans-serif;
  }
}

.content-wrap .sidebar .radio-item {
  width: 36px;
  height: 36px;
  border-radius: 9px;
  border: 1px solid #f0f0f0;
}

.mail-wrap .sidebar {
  position: fixed;
  top: 77px;
  left: 0;
  height: 100%;
  // height: calc(100% - 184px);
  // overflow-y: auto;
  background: #fff;
  border-right: 1px solid #eeeff3;
  // z-index: 99;
}

.mail-wrap .sidebar ul {
  margin: 0;
}

.mail-wrap .sidebar .sidebar-action {
  padding: 0 10px;
  height: calc(100% - 184px);
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
}

.mail-wrap.sidebar-open .sidebar .sidebar-action {
  padding: 0 10px;
  height: calc(100% - 77px);
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
}

.mail-wrap .sidebar .sidebar-action .action-item {
  padding: 10px 0;
  // position: relative
}

// .mail-wrap .sidebar .sidebar-action .action-item+.action-item:before {
// 	content: '';
// 	position: absolute;
// 	width: 90%;
// 	height: 1px;
// 	border-top: 1px solid #eeeff3;
// 	left: 5%;
// 	top: 0
// }

.mail-wrap .sidebar .sidebar-action .action-item.action-new {
  padding: 10px 5px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (min-width: 1200px) {
  .mail-wrap .sidebar {
    width: 230px;
  }
  .mail-wrap .content {
    width: calc(100% - 280px);
  }
  .mail-wrap.sidebar-toggler .sidebar {
    width: 72px;
    overflow: hidden;
  }
  .mail-wrap.sidebar-toggler .sidebar .action-new {
    padding: 10px 0;
  }
  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new {
    overflow: hidden;
    text-align: center;
    display: inline;
    border-radius: 10px;
    float: left;
  }
  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new .icon-new {
  }

  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new span {
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
  }
  .mail-wrap.sidebar-toggler .sidebar .list-action li a [class*="icon-"] {
    padding-left: 6px;
  }
  .mail-wrap.sidebar-toggler .sidebar .list-action li a span {
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
  }

  .mail-wrap.sidebar-toggler.sidebar-open
    .sidebar
    .list-action
    li
    a
    span.sub-action {
    transform: scaleX(1);
    visibility: visible;
    transition: 0.3s;
    opacity: 1;
  }

  .mail-wrap.sidebar-open .sidebar .list-action li a span.txt-lable.sub-action {
    margin-left: 28px;
  }

  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new span {
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
  }

  .mail-wrap.sidebar-toggler
    .sidebar
    .list-action
    li.has-note
    a
    [class*="icon-"]:after {
    right: -3px;
  }
  .mail-wrap.sidebar-toggler .content {
    width: calc(100% - 122px);
  }
}

@media (max-width: 1199px) {
  // .mail-wrap:not(.sidebar-open) .sidebar {
  // 	width: 72px;
  // 	overflow: hidden
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar .action-new {
  // 	padding: 10px 0
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar .action-new .btn-new {
  // 	overflow: hidden;
  //     text-align: center;
  //     border-radius: 10px;
  // }

  // .mail-wrap:not(.sidebar-open) .sidebar .action-new .btn-new span {
  // 	transform: scaleX(0);
  // 	visibility: hidden;
  // 	opacity: 0
  // }

  // .mail-wrap:not(.sidebar-open) .sidebar .action-new .btn-new-content .refresh-icon {
  // 	transform: scaleX(0);
  // 	visibility: hidden;
  //     opacity: 0;
  //     display: none;
  // }

  // .mail-wrap:not(.sidebar-open) .sidebar .list-action li a [class*="icon-"] {
  // 	padding-left: 4px
  // }

  //not open sidebar

  .mail-wrap.sidebar-toggler .sidebar {
    width: 72px;
    overflow: hidden;
  }
  .mail-wrap.sidebar-toggler .sidebar .action-new {
    padding: 10px 0;
  }
  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new {
    overflow: hidden;
    text-align: center;
    display: inline;
    border-radius: 10px;
    float: left;
  }
  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new .icon-new {
  }

  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new span {
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
  }
  .mail-wrap.sidebar-toggler .sidebar .list-action li a [class*="icon-"] {
    padding-left: 6px;
  }
  .mail-wrap.sidebar-toggler .sidebar .list-action li a span {
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
  }

  .mail-wrap.sidebar-toggler .sidebar .action-new .btn-new span {
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
  }

  .mail-wrap.sidebar-open.sidebar-toggler .sidebar .list-action li a span.sub-action {
    transform: scaleX(1);
    visibility: visible;
    transition: 0.3s;
    opacity: 1;
  }

  .mail-wrap.sidebar-open.sidebar-toggler .sidebar .list-action li a span.txt-lable.sub-action {
    margin-left: 28px;
  }

  .mail-wrap.sidebar-toggler
    .sidebar
    .list-action
    li.has-note
    a
    [class*="icon-"]:after {
    right: -3px;
  }
  .mail-wrap.sidebar-toggler .content {
    width: calc(100% - 122px);
  }

  .mail-wrap .sidebar {
    width: 230px;
  }

  .mail-wrap .content {
    width: calc(100% - 280px);
  }
  .img {
    &__overlay {
      position: relative;
      padding-left: 4px;
      display: inline-table;
      width: 22px;
      height: 22px;
      margin-left: 9px;
      transition: 0.3s;
    }
    &__left-sidebar {
      display: block;
    }
    &__overlay:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #ffc542;
      border-radius: 50%;
      top: -3px;
      right: -1px;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .content-wrap .sidebar .video-items .video-item .thumbnail {
    width: 90px;
  }
  .content-wrap .sidebar .video-items .video-item .video-info {
    width: calc(100% - 100px);
  }
}

@media (max-width: 565px) {
  // .mail-wrap:not(.sidebar-open) .sidebar {
  // 	width: 50px
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar [class*="icon-"] {
  // 	font-size: 16px
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar .sidebar-action {
  // 	padding: 0 5px
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar .sidebar-action .action-item.action-new {
  // 	padding: 10px 0
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar .sidebar-action .action-item.action-new .btn-new [class*="icon-"] {
  // 	padding-left: 0
  // }
  // .mail-wrap:not(.sidebar-open) .sidebar .list-action li a [class*="icon-"] {
  // 	padding-left: 2px
  // }
  .mail-wrap .content {
    width: calc(100% - 280px);
  }
  .mail-wrap .content .mail-info:not(.mail-check-all) .mail-info-right {
    padding-right: 60px;
  }
  .mail-wrap .content .mail-info .mail-info-right li + li {
    margin-left: 20px;
  }
  .mail-wrap .content .mail-info .mail-info-right li.total-inbox .inbox-arrow {
    margin-left: 15px;
  }
  .mail-wrap
    .content
    .mail-info
    .mail-info-right
    li.total-inbox
    .inbox-arrow
    a
    + a {
    margin-left: 15px;
  }
  .mail-wrap .content .mail-items .mail-item:after {
    height: calc(100% - 10px);
    top: 5px;
  }
  .mail-wrap .content .mail-content-inbox .note {
    font-size: 12px;
  }
  .mail-wrap .content .mail-content-inbox .note .hide {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .content-wrap .sidebar {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    // z-index: 99;
  }
  .content-wrap .sidebar .sidebar-wrap {
    overflow-y: auto;
    height: 100%;
    padding: 20px;
  }
  .content-wrap .sidebar .show-sidebar {
    display: block;
  }
  .content-wrap .sidebar.show {
    right: 0;
    -moz-box-shadow: 0px 0px 10px 0px rgba(23, 23, 37, 0.2);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(23, 23, 37, 0.2);
    -ms-box-shadow: 0px 0px 10px 0px rgba(23, 23, 37, 0.2);
    -o-box-shadow: 0px 0px 10px 0px rgba(23, 23, 37, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(23, 23, 37, 0.2);
  }
}

@media (max-width: 767px) {
  .sidebar .show-sidebar {
    top: 60px;
  }
}

@media (max-width: 400px) {
  .content-wrap .sidebar {
    width: 280px;
    right: -280px;
  }
}

.bottom-compose {
  position: fixed;
  bottom: 10px;
  width: 630px;
  background-color: white;
  right: 10px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.reply-email-box {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  margin: 20px;
  // max-height: 271px;
}

.modal.fade .modal-dialog {
  float: right;
  margin-right: 100px;
  margin-top: 100px;
}

.modal-dialog-centered {
  align-items: flex-start;
}

.modal-label .modal-dialog {
  float: none !important;
  margin-right: auto !important;
  margin-top: 100px !important;
}

.compose-email {
  float: none !important;
  margin-right: auto !important;
  align-items: center !important;
  margin-top: 0 !important;
}

.tag-placeholder::placeholder {
  color: #c3c3ce;
}

.reply-bottom-box {
  display: flex;
  width: 100%;
}

.icon-reply::before {
  color: #92929d;
  font-size: 18px;
}

// Header action
.action-group {
  display: flex;
  margin-left: 20px;
  margin-bottom: 0;
}

.action-group li span {
  margin-right: 10px;
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.action-group li span img {
  width: 100%;
  max-width: fit-content;
}

.action-group-big li span {
  // margin-right: 26px;
  font-size: 20px;
}

.form-check .form-check-not-total:after,
.form-check .form-check-not-total:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: 0.3s;
}
.form-check .form-check-not-total:before {
  content: "-";
  left: 5px;
  font-size: 16px;
  color: #fff;
  z-index: 2;
}

.form-check .form-check-not-total:after,
.form-check .form-check-input:checked + .form-check-not-total:after {
  width: 18px;
  height: 18px;
  border: 1px solid #92929d;
  border-radius: 2px;
  left: 0;
}

.form-check .form-check-input:checked + .form-check-not-total:before {
  opacity: 1;
}

.form-check .form-check-input:checked + .form-check-not-total:after {
  /* border-color: #fe5810; */
  border: 0 solid;
  background: #344563;
}

.select-all-text {
  color: #979797;
  margin-left: 5px;
  cursor: pointer;
}

li.total-inbox {
  display: flex;
  -moz-align-items: center;
  align-items: center;
  color: #344563;
}

li.total-inbox .inbox-arrow {
  display: flex;
  margin-left: 25px;
}

li.total-inbox .inbox-arrow a i {
  font-size: 18px;
}

li.total-inbox .inbox-arrow a {
  margin-right: 20px;
}

.module-right {
  position: sticky;
  top: 75px;
  z-index: 1;
  width: 50px;
  float: right;
  min-height: calc(100vh - 80px);
  border-left: 1px solid #eeeff3;
  text-align: center;
}

.module-right .list-action-more {
  list-style: none;

  li {
    padding: 5px;
    margin-bottom: 2px;
  }

  li a {
    text-decoration: none;
    font-size: 17px;
  }
}

.module-right .list-action-more .calendar {
  color: #4e6cfb;
}

.module-right .list-action-more .security {
  color: #82c43c;
}

.module-right .list-action-more .emoji {
  color: #ffa459;
}

.module-right .add-widget {
  position: relative;
}

.module-right .add-widget a {
  color: #fe5810;
  text-decoration: none;
  font-size: 16px;
}

.module-right .add-widget:hover:before {
  transform: translate(-50%, -50%) scale(1);
}

.module-right .add-widget:before {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background: rgba(254, 88, 16, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.3s;
}
.btn-primary-original:hover {
  background-color: #007bff !important;
}
.btn-primary-original {
  background-color: #007bff !important;
}

.new-label-sidebar {
  background-color: unset;
  width: 100%;
}

.new-label-name {
  width: 100%;
  padding: 2px;
  margin-bottom: 10px;
  display: block;
}

.new-label-nest-checkbox {
  display: inline;
}
.new-label-nest-title {
  display: inline;
  margin-left: 5px;
}

.new-label-nest-select {
  display: block;
  width: 100%;
  padding: 5px;
}

.create-label {
  display: flex;
  padding: 8px 10px;
}

.modal-footer-label {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}
.modal-footer-label button {
  margin-left: 20px;
}

.icon-pagination {
  color: rgba(0, 0, 0, 0.3);
}
.icon-disable {
  color: rgba(0, 0, 0, 0.15);
}

html:not([dir="rtl"]) .form-check {
  padding-left: 0;
}
.check-box-mail {
  padding-left: 6px !important;
  padding-bottom: 6px;
}

.alert-n {
  position: absolute;
  top: 25px;
  color: white;
  background: #fc5a5a;
  font-size: 12px;
  border-radius: 4px;
  min-width: 50px;
  max-width: 50px;
  height: 20px;
  line-height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  right: 1px;
  text-align: center;
}
