//
// Base :: Utility Classes
// --------------------------------------------------

/// Margin, Padding
/// Used to override styles on components without need for
/// additional modifier classes
///
/// Usage:
/// <div class="u-mb0"> // margin-bottom: 0
/// <div class="u-mt20"> // margin-top: 20px

$property-map: (
  m: margin,
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left,
  p: padding,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left
);
$sizes-list: 0 5 10 15 20 25 30;

@each $size in $sizes-list {
  $val: $size + px;

  @each $keyword, $property in $property-map {
    .u-#{$keyword}#{$size} {
      #{$property}: $val;
    }
  }
}

/// Auto
@each $keyword, $property in $property-map {
  .u-#{$keyword}-auto {
    #{$property}: auto;
  }
}
