// ======================================================
// Abstracts :: Mixins
// ======================================================

// Responsive
// We use Bootstrap's mixin breakpoint
// https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
//
// @include media-breakpoint-down(sm) {
//   code here
// }

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
$breakpoints: (
  smallphone-and-up: '(max-width: 320px)',
  smallphone-and-down: '(max-width: 480px)',
  middlephone-and-down: '(max-width: 600px)',
  phone-and-down: '(max-width: 767px)',
  tablet-and-down: '(max-width: 991px)',
  large-tablet-and-down: '(max-width: 1023px)',
  desktop-and-down: '(max-width: 1150px)',
  large-desktop-and-down: '(max-width: 1350px)',
  tablet-and-up: '(min-width: 768px)',
  desktop-and-up: '(min-width: 992px)',
  large-desktop-and-up: '(min-width: 1150px)',
  tablet-and-desktop: '(min-width: 768px) and (max-width: 1150px)',
  phone-and-tablet: '(min-width: 768px) and (max-width: 991px)',
  middlephone-and-phone: '(min-width: 600px) and (max-width: 767px)',
  large-tablet-and-up: '(min-width: 1024px) and (max-width: 1199px)',
  desktop-and-large-desktop: '(min-width: 992px) and (max-width: 1350px)',
);

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media screen and #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
      + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Truncate
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Animate overright
:root {
  --animate-delay: 0.2s;
}

// Box style
@mixin icon-box-style() {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 14px;
  box-shadow: 0 6px 14px 0 rgba(221, 220, 220, 0.5);
  background-color: #ffffff;
}

@mixin sidebar-collapse {
  height: calc(100vh - 72px);
  overflow: auto;

  // chat
  .sw-sidebar__search__input {
    background: url('../assets/icon-search-sidebar.svg') no-repeat 17px center $light_02;

    @at-root .dark-mode #{&} {
      background-color: transparent !important;
    }
  }

  .sw-sidebar__avatar {
    margin-right: 0;
  }

  .sw-sidebar__search {
    order: 2;
    margin-bottom: 15px;
  }

  .sw-sidebar__tab {
    order: 3;
    flex-direction: column;
    margin-bottom: 0;
    padding-top: 10px;
    border-top: 1px solid #d8d8d8;
  }

  .sw-sidebar__info {
    order: 1;
    justify-content: center;
  }

  .sw-sidebar__name-option,
  .sw-sidebar__info-control,
  .sw-user-friend__name-option,
  .sw-sidebar__search__button,
  .sw-sidebar__tab-item__text,
  .sw-favorites__sort__text {
    display: none;
  }

  .sw-sidebar__search {
    width: 50px;
    border-radius: 12px;
  }

  .sw-sidebar__tab-item {
    width: auto;
    margin-bottom: 10px;
  }

  .sw-favorites {
    flex-direction: column;
    padding: 0 0 25px;
    border-bottom: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 20%;
      right: 20%;
      width: 60%;
      height: 1px;
      background: #e4e4e4;
    }
  }

  .sw-sidebar__header {
    border-bottom: 0;
  }

  .sw-favorites__sort {
    order: 2;

    svg {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .sw-favorites__save {
    order: 1;
  }

  .sw-favorites__list {
    order: 3;
    justify-content: center;
  }

  .sw-favorites__item {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .sw-user-friend__avatar {
    margin-right: 0;
    width: 42px;
    height: 42px;

    img {
      max-width: 42px;
      max-height: 42px;
    }
  }

  .sw-user-friend__list {
    padding: 10px 0;
    overflow: visible;
  }

  .sw-user-friend__item {
    padding: 10px 16px;
    margin-top: 0;

    @at-root .dark-mode #{&} {
      box-shadow: none;

      &:hover {
        background: #393b4e;
      }
    }

    &:hover {
      background: $primary-hover;
    }
  }

  .sw-user-friend__alert {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .sw-user-friend__online {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    z-index: 9;

    &.green {
      background: $green;
    }

    &.orange {
      background: $orange;
    }

    &.gray {
      background: $gray;
    }
  }

  // call
  .sw-sidebar-call-history {
    height: 100%;

    &__description,
    &__control {
      display: none;
    }

    &__avatar {
      margin-right: 0;
      max-width: 42px;
      max-height: 42px;
    }
  }

  .sw-sidebar {
    &__notifications {
      .react-select-container {
        max-width: 100%;
      }
    }
  }

  .sw-notification {
    &__description,
    &__media {
      display: none;
    }
  }
}
