.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0px 2px 10px #999999;
  z-index: 1000;
  border-radius: 5px;

  &--option {
    padding: 6px 50px 5px 10px;
    min-width: 100px;
    cursor: default;
    font-size: 12px;
    // position: relative;

    &:hover {
      // background: linear-gradient(to top, #2faff9,#2686d4);
      background: #2686d4;
      color: white;
    }

    &:active {
      color: #e9e9e9;
      // background: linear-gradient(to top, #555, #444);
      background: #2686d4;
    }

    &__disabled {
      color: #999999;
      pointer-events: none;
    }
  }

  &--separator {
    width: 100%;
    height: 1px;
    background: #cccccc;
    margin: 0 0 0 0;
  }

  hr {
    margin: 0 !important;
  }

  #menu {
    list-style: none;
    cursor: default;
    width: 240px;
    padding: 10px 0;
    background: #fff;
    margin-bottom: 0;
    border-radius: 5px;
  }
  #menu li {
    text-align: left;
    color: #b5b5be;
  }
  #menu li a {
    text-decoration: none;
    font-size: 14px;
    display: block;
    padding: 10px;
    color: #747479;
    background-color: #fff;
  }
  #menu > li {
    position: relative;
  }
  #menu > li > ul.dropdown_menu {
    position: absolute;
    list-style: none;
    display: none;
    padding: 10px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #999999;
    top: 0;
  }
  #menu > li:hover > a {
    background-color: #fafafa;
    color: black;
  }
  #menu > li:hover > ul.dropdown_menu {
    z-index: 100;
    display: block;
    left: 241px;
  }
  #menu > li:hover > ul.dropdown_menu.c-right {
    right: 241px;
    left: unset;
  }
  #menu > li:hover > ul.dropdown_menu > li {
    width: 200px;
    position: relative;
  }
  #menu > li:hover > ul.dropdown_menu > li:hover > a {
    color: black;
  }
  ul.dropdown_menu > li > ul.submenu {
    position: absolute;
    display: none;
    left: 200px;
    list-style: none;
    width: 200px;
  }
  ul.dropdown_menu > li:hover > a {
    background-color: #fafafa !important;
  }
  .arrow {
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }
  .arrow-down {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #9b9ba2;
  }
  .arrow-right {
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #9b9ba2;
    float: right;
    top: 2px;
    position: relative;
  }
}
