.fromName {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 400px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

.countOfReplies {
  font-size: 0.9em;
  font-weight: 400;
}

.lastBodyReply {
  font-weight: 400;
}

.iconBtn {
  background-color: transparent;
  cursor: pointer;
}
.strongText {
  font-weight: 900;
}
.isSeen {
  background-color: #f5f7f7;
}