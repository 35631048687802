.createFilterForm {
  position: absolute;
  z-index: 1001;
  padding: 20px;
  background-color: white;
  min-width: 10rem;
  font-size: 14px;
  width: 100%;
  color: #696974;
  border: 0.5px solid #ced4da;
  border-radius: 4px;
}
.createFilterFormHeader {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.iconBack {
  width: 40px;
  height: 40px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
div.iconCheck {
  padding-left: 0 !important;
  width: 20px;
  height: 30px;
  display: flex;
  margin: 5px 10px;
}
.listCheckBox {
  margin-top: 10px;
}
.itemCheck {
  display: flex;
  align-items: center;
}
.createFilterFormFooter {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}
.learnMore {
  font-weight: 600;
  opacity: 0.7;
}
.selectLabel {
  margin: 0 20px;
  cursor: pointer;
}
