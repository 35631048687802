//
// Base :: Reset
// --------------------------------------------------


h1,
h2,
h3,
h4,
h5,
h6 {
	color: #344563
}

h2 {
	font-size: 24px
}

h3 {
	font-size: 20px
}

h4 {
	font-size: 16px
}

.btn {
	border: 1px solid #d5d5dc;
	background: #fff;
	color: #696974;
	padding: 5px 15px;
	border-radius: 5px
}

.btn:hover {
	background: rgba(254, 88, 16, 0.1);
	border-color: rgba(254, 88, 16, 0.1)
}

.btn:focus {
	box-shadow: none;
	outline: none
}

.btn-primary {
	border-color: #FE5810;
	background: #FE5810;
	color: #fff
}

.btn-primary:hover {
	background: #ce460a;
	border-color: #ce460a
}

// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// menu,
// nav,
// section {
//   display: block;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// ol,
// ul {
//   margin: 0;
//   padding: 0;
// }

// ol,
// ul {
//   list-style: none;
// }

// a {
//   color: $primary;

//   &,
//   &:focus,
//   &:hover {
//     outline: none;
//     text-decoration: none;
//     cursor: pointer;
//   }
// }

// button {
//   &,
//   &.button {
//     &:focus {
//       outline: 0 none;
//     }
//   }

//   &:hover {
//     cursor: pointer;
//   }
// }

// button::-moz-focus-inner {
//   border: 0;
// }

// input[type='checkbox']:focus {
//   outline: 0;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6 {
//   font-weight: 400;
//   color: inherit;
// }

// :focus {
//   outline: none;
// }

// * {
//   margin: 0;
//   padding: 0;

//   &,
//   &:before,
//   &:after {
//     box-sizing: border-box;
//   }
// }

// body {
//   line-height: 1;
//   background-color: $white;
//   font-family: 'Roboto', sans-serif;
//   overflow-x: hidden;
// }

// input {
//   &:focus {
//     outline: none;
//   }

//   &::placeholder {
//     color: $primary;
//   }

//   &[type='number'] {
//     &::-webkit-inner-spin-button,
//     &::-webkit-outer-spin-button {
//       -webkit-appearance: none;
//       -moz-appearance: none;
//       appearance: none;
//     }
//   }
// }

// input:-ms-input-placeholder {
//   color: $primary !important;
// }

// textarea {
//   &:focus {
//     outline: none;
//   }

//   &::placeholder {
//     color: $primary;
//   }
// }

// textarea:-ms-input-placeholder {
//   color: $primary !important;
// }

// table {
//   width: 100%;
// }

// img {
//   max-width: 100%;
//   vertical-align: middle;
// }
