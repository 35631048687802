.compose_email_form input[type="text"] {
  background-color: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0;
  padding: 6px 12px;
  width: 100%;
  border: 1px solid #ebe9e9;
  border-radius: 5px 5px 5px 5px;
}

.sendButton {
  display: inline-flex;
  min-width: 40px;
  justify-content: space-between;
  margin-right: 20px;
}
div.react_tagsinput input {
  background: transparent;
  color: #777;
  padding: 5px !important;
  text-align: left;
}

.selectedEmail {
  background: none;
  /* padding: 0 25px 0 10px; */
  border-radius: 10px;
  margin-right: 10px;
  position: relative;
  border-color: #d5d5dc;
  min-height: 41px;
  flex: 1;
}

.tagSelection {
  background: none;
  padding: 0px 25px 0px 10px;
  border-radius: 10px;
  margin-right: 10px;
  position: relative;
  border: 1px solid #d5d5dc;
  display: inline-block;
  height: 32px;
  padding-top: 4px;
}

.errorTag {
  background-color: #d93025;
  color: white;
  margin-bottom: 10px;
  padding-right: 5px;
}

.errorTag a::before {
  content: url("../../../assets/images/ic_white_close.png");
  margin-top: 10px;
  margin-left: 10px;
}

.tagInput {
  width: 100%;
  border: none;
  padding: 5px;
  outline: none;
  max-width: 200px;
}

.tagInput::placeholder {
  color: "#c3c3ce";
}

.group-input {
  margin-bottom: 0;
}

.tagSelectionRemove {
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  display: block;
  font-size: 20px;
  color: #92929d;
  top: 45%;
  transform: translateY(-50%);
  right: 5px;
  margin: 0;
}

.tagSelectionRemove::before {
  content: url("../../../assets/images/ic_close_tag.png");
}

.composeHeader {
  background-color: #334464;
  padding: 15px;
  padding-bottom: 10px;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  display: flex;
  justify-content: space-between;
}

.composeHeaderMin {
  padding: 0;
  min-width: 260px;
}

.titleMin {
  margin-left: 10px;
  color: white;
  margin-bottom: 0;
  text-align: left;
}

.composeHeaderTitle {
  color: white;
  font-size: 16px;
  margin: 0;
  font-family: poppinsSemiBold !important;
}

.btnGroupComposer {
  display: flex;
}

.btnComposer {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.documentTitle {
  max-width: 90px;
  word-wrap: break-word;
  margin-top: 15px;
  font-size: 13px;
  color: #334464;
}

.smallHeader {
  color: white;
  flex: 1;
  background-color: #334464;
  cursor: pointer;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-right: 0;
  padding-left: 0;
}

.titleGroup {
  flex: 1;
  cursor: pointer;
}

.sendToGroup {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #f1f1f5;
  padding: 0 !important;
}

.groupCcBtn {
  display: flex;
}

.btnEmail {
  background: none;
  border: none;
  outline: none;
  color: #969696;
  cursor: pointer;
}

.noLine {
  border: none !important;
  max-width: calc(100% - 80px);
}

.hiden {
  visibility: hidden;
}

.subjectBox {
  border-radius: 0 !important;
  border: none !important;
  height: 37px !important;
}

.footerCompose {
  padding: 0 !important;
}

.borderBodyBox {
  border-radius: 5px;
}
