// ======================================================
// Layout :: Email settings header
// ======================================================

header {
  background: #fff;
  padding: 25px 0;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

header.show {
  z-index: 999;
}

header.header-fixed {
  padding: 10px 0;
  -moz-box-shadow: 0px 0px 5px 0px rgba(50, 54, 67, 0.5);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 54, 67, 0.5);
  -ms-box-shadow: 0px 0px 5px 0px rgba(50, 54, 67, 0.5);
  -o-box-shadow: 0px 0px 5px 0px rgba(50, 54, 67, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(50, 54, 67, 0.5);
}

header .navbar {
  padding: 0;
  line-height: 1;
}

header .navbar .navbar-logo .navbar-brand {
  padding: 0;
  margin: 0;
}

header .navbar .navbar-logo .logo {
  width: 87px;
  height: 35px;
  background-size: 100%;
  background-repeat: no-repeat;
}

header .navbar-toggler {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 0;
  margin-right: 20px;
  overflow: hidden;
  display: none;
}

header .navbar-toggler .icon-bar {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #344563;
  left: 0;
  top: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

header .navbar-toggler .icon-bar:nth-child(2) {
  top: 7px;
}

header .navbar-toggler .icon-bar:last-child {
  top: 14px;
}

header .navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

header .navbar-toggler.show-mailbar {
  height: 20px;
}

header .navbar-toggler.show-mailbar .icon-bar:first-child {
  top: 8px;
  transform: rotate(45deg);
}

header .navbar-toggler.show-mailbar .icon-bar:nth-child(2) {
  left: 100%;
  opacity: 0;
}

header .navbar-toggler.show-mailbar .icon-bar:last-child {
  top: 8px;
  transform: rotate(-45deg);
}

header .header-search {
  flex: auto;
  width: auto;
  margin: 0 20px;
}

header .header-group {
  margin-left: auto;
}

header .header-group .nav-infomation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 0;
}

header .header-group .nav-infomation li + li {
  margin-left: 15px;
}

header .header-group .nav-infomation .notifications a {
  text-decoration: none;
  color: #323643;
}

header .header-group .nav-infomation .notifications [class*="icon-"] {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 27px;
  text-align: center;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

header .header-group .nav-infomation .notifications [class*="icon-"]:hover {
  background-color: rgba(254, 88, 16, 0.2);
}

header .header-group .nav-infomation .notifications.email a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

header .header-group .nav-infomation .notifications.email a:hover {
  color: #fe5810;
}

header
  .header-group
  .nav-infomation
  .notifications.email
  a:hover
  [class*="icon-"] {
  background-color: rgba(254, 88, 16, 0.2);
  color: #323643;
}

header .header-group .nav-infomation .notifications.show [class*="icon-"] {
  background-color: rgba(254, 88, 16, 0.2);
}

header .header-group .nav-infomation .action-btn .btn {
  padding: 6px 25px;
  font-size: 16px;
}

header .header-group .nav-infomation .user-dropdown a {
  text-decoration: none;
}

header .header-group .nav-infomation .user-dropdown > a {
  width: 42px;
  height: 42px;
  padding-top: 3px;
  border-radius: 50%;
  display: block;
}

header .header-group .nav-infomation .user-dropdown > a:hover {
  background-color: rgba(254, 88, 16, 0.2);
}

header .header-group .nav-infomation .user-dropdown .avatar {
  display: block;
  width: 36px;
  height: 36px;
  margin: 0 auto;
  background-size: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
}

header.header-mail {
  padding: 15px 25px;
  margin-bottom: 0;
  border-bottom: 1px solid #e2e2ea;
  z-index: 999;
}

header.header-mail .navbar-toggler {
  display: block;
}

header.header-mail .header-search {
  max-width: 670px;
  margin: 0 75px;
}

header .header-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

@media (max-width: 991px) {
  header:not(.header-mail) .header-group .nav-infomation li + li {
    margin-left: 5px;
  }
  header:not(.header-mail)
    .header-group
    .nav-infomation
    .notifications.email
    span {
    display: none;
  }
  header:not(.header-mail) .header-group .nav-infomation .action-btn .btn {
    padding: 6px 15px;
    font-size: 14px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  header.header-mail {
    padding: 15px;
  }
  header.header-mail .header-search {
    margin: 0 35px;
  }
}

@media (max-width: 767px) {
  header {
    padding: 15px 0;
    margin-bottom: 10px;
  }
  header.header-mail {
    padding: 10px 15px;
    margin-bottom: 0;
  }
  header.header-mail .header-search {
    margin: 0 20px;
  }
  header.header-mail .header-group .nav-infomation li + li {
    margin-left: 5px;
  }
  .main-mail {
    padding-top: 63px;
  }
}

@media (max-width: 420px) {
  header:not(.header-mail) .navbar .navbar-logo .logo {
    width: 115px;
    height: 26px;
  }
  header:not(.header-mail) .header-group .nav-infomation li + li {
    margin-left: 0;
  }
  header:not(.header-mail)
    .header-group
    .nav-infomation
    .notifications
    [class*="icon-"] {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
  }
  header:not(.header-mail)
    .header-group
    .nav-infomation
    .user-dropdown
    .avatar {
    width: 32px;
    height: 32px;
  }
  header:not(.header-mail) .header-group .nav-infomation .action-btn {
    margin-left: 5px;
  }
  header:not(.header-mail) .header-group .nav-infomation .action-btn .btn {
    padding: 6px;
    font-size: 12px;
  }
  header.header-mail {
    padding: 10px;
  }
  header.header-mail
    .header-group
    .nav-infomation
    .notifications
    [class*="icon-"] {
    font-size: 18px;
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
}

@media (max-width: 360px) {
  header.header-mail .navbar .navbar-toggler {
    margin-right: 10px;
  }
  header.header-mail .navbar .navbar-logo .logo {
    width: 115px;
    height: 26px;
  }
}

.header-search .search-toggler {
  display: block;
  color: #323643;
}

.header-search .form-search-top {
  font-size: 14px;
  position: relative;
  -moz-box-shadow: 0 5px 10px 0 rgba(226, 226, 234, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(226, 226, 234, 0.1);
  -ms-box-shadow: 0 5px 10px 0 rgba(226, 226, 234, 0.1);
  -o-box-shadow: 0 5px 10px 0 rgba(226, 226, 234, 0.1);
  box-shadow: 0 5px 10px 0 rgba(226, 226, 234, 0.1);
}

.header-search .form-search-top .form-control {
  font-size: 14px;
}

.header-search .form-search-top .form-control:focus + .btn-search {
  background: #fe5810;
  color: #fff;
}

.header-search .form-search-top .form-control::placeholder {
  color: #d5d5dc;
}

.header-search .form-search-top .btn-search {
  position: absolute;
  background: #f1f1f5;
  border: none;
}

.header-search .form-search-top .btn-search:hover {
  background: #fe5810;
  color: #fff;
}

@media (min-width: 992px) {
  .header-search .form-search-top .form-control {
    padding-right: 105px;
  }
  .header-search .form-search-top .btn-search {
    width: 90px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .header-search .form-search-top .form-control {
    padding-right: 75px;
  }
  .header-search .form-search-top .btn-search {
    width: 60px;
  }
}

@media (min-width: 768px) {
  .header-search .search-toggler {
    display: none;
  }
  .header-search .form-search-top {
    border-radius: 5px;
  }
  .header-search .form-search-top .form-control {
    height: 46px;
  }
  .header-search .form-search-top .btn-search {
    border-radius: 0 5px 5px 0;
    height: calc(100% - 2px);
    top: 1px;
    right: 1px;
  }
}

@media (max-width: 767px) {
  .header-search .search-toggler {
    display: none;
  }
  .header-search .form-search-top .form-control {
    height: 40px;
    border-radius: 38px;
    padding-right: 45px;
  }
  .header-search .form-search-top .btn-search {
    width: 38px;
    padding: 5px;
    border-radius: 50%;
    height: calc(100% - 4px);
    top: 2px;
    right: 3px;
  }
}

@media (max-width: 640px) {
  header:not(.header-mail) .header-search .search-toggler {
    top: 50%;
    right: 235px;
  }
  header:not(.header-mail) .header-search .form-search-top .form-control {
    background: #fff;
  }
  .header-search .search-toggler {
    display: block;
    line-height: 1;
    text-decoration: none;
    position: absolute;
    top: 54%;
    transform: translateY(-50%);
    right: 160px;
  }
  .header-search .form-search-top {
    position: absolute;
    width: 100%;
    background: rgba(254, 88, 16, 0.1);
    padding: 5px 10px;
    top: 100%;
    left: 0;
    visibility: hidden;
    transform-origin: top;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    opacity: 0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }
  .header-search .form-search-top .btn-search {
    height: calc(100% - 14px);
    top: 7px;
    right: 13px;
  }
  .header-search .form-search-top.open {
    visibility: visible;
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
    opacity: 1;
  }
}

@media (max-width: 420px) {
  header:not(.header-mail) .header-search .search-toggler {
    right: 160px;
  }
  .header-search .search-toggler {
    right: 125px;
  }
}

header.header + main {
  padding-top: 101px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  header.header + main {
    padding-top: 82px;
    padding-bottom: 50px;
  }
}

a.link-admin {
  color: #000000;
  text-decoration: none;
}