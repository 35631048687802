.category-item {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: rubikRegular, sans-serif;
  color: #44444F;

  button {
    min-width: 64px;
    height: 32px;
    border-radius: 6px;
    background-color: #F6F6FD;
    color: #44444F;
    padding: 5px 15px;
  }
}