/* Custom Ant Design */
.ant-btn:hover, .ant-btn:focus {
  color: #FF570D;
  border-color: #FF570D;
}

.ant-btn-primary {
  background: #FF570D;
  border-color: #FF570D;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: rgba(255, 87, 13, 0.75);
  border-color: rgba(255, 87, 13, 0.75);
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #FF570D;
  border-color: #FF570D;
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: rgba(255, 87, 13, 0.75);
  border-color: rgba(255, 87, 13, 0.75);
}

.ant-btn-text {
  border: none;
}

.ant-btn-text:hover, .ant-btn-text:focus {
  background-color: rgba(254, 88, 16, 0.1);
  color: unset;
}

.ant-btn-link {
  padding: 4px 0;
  border: none;
  color: #FF570D;
}

.ant-btn-link:hover, .ant-btn-link:focus {
  color: rgba(255, 87, 13, 0.75);
}

.ant-input:not(.ant-input-borderless), .ant-select-selector:not(.ant-input-borderless) {
  border: 1px solid #F1F1F5;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: white;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper {
  border-color: #F1F1F5;
}

.ant-input::placeholder {
  color: #B5B5BE;
}

.ant-input:not(.ant-input-borderless):hover {
  border-color: rgba(255, 87, 13, 0.75);
}

.ant-input:not(.ant-input-borderless):focus {
  border-color: rgba(255, 87, 13, 0.75);
  box-shadow: 0 0 0 2px rgba(255, 87, 13, 0.25);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: rgba(255, 87, 13, 0.75);
  box-shadow: 0 0 0 2px rgba(255, 87, 13, 0.25);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: rgba(255, 87, 13, 0.75);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgba(255, 87, 13, 0.75);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: rgba(255, 87, 13, 0.75);
  box-shadow: 0 0 0 2px rgba(255, 87, 13, 0.25);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #FFF2E8
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) p {
  color: #FF570D;
}

.ant-switch-checked {
  background-color: #FF570D;
}

.ant-picker-calendar .ant-picker-panel {
  border-top: none;
}

.ant-btn-text.ant-btn-circle {
  display: flex;
  justify-content: center;  
  align-items: center;
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 9px;
  
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 9px;
  border-color: #FFC20D;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #FFC20D;
}

.ant-pagination-item-active,
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #FF570D;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #FF570D;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #FF570D;
  border-color: #FF570D;
}

/* Admin */
.ant-layout-sider,
.ant-layout-sider .ant-menu.ant-menu-dark,
.ant-layout-sider .ant-menu-dark .ant-menu-sub,
.ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #223555;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 87, 13, 0.25);
  border-right: 5px solid #FF570D;
}

/* Fix Icon */
svg {
  vertical-align: unset !important;
}
