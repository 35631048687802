.mail-option-list {
  display: flex;
  margin-left: auto;
  margin-bottom: 0;
}

.mail-option-list {
  display: flex;
  margin-left: auto;
  li {
    margin-left: 20px;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
