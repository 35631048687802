.wrapper {
  width: 100%;
  float: left;
  height: calc(100vh - 131px);
  overflow-y: auto;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
}

.selectAllDropdown {
  display: inline-block;
  margin-left: 5px;
}

.caretCheckbox {
  border-top: 5px solid #b5b5be;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  cursor: pointer;
  border-radius: 4px;
}

.icSubHeader {
  object-fit: "contain";
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.caretPos {
  margin-left: -10px;
}

.noResultWraper {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #f6f6f9;
}

.noResultText {
  color: #b5b5be;
}

.MoreDropDown .DropDownToggle{
  border: none;
  background: white;
  color: #696974;
  padding: 0;
}
.MoreDropDown button.DropDownToggle:hover{
  border: none;
  background: white;
  color: #FE5810;
}
.MoreDropDown button.btn-primary:hover{
  box-shadow: none;
  background-color: white;
  color: #FE5810;
}
a.MoreDropDownItem {
  height: 35px;
  padding: 0.6rem 1.5rem;
  font-size: 14px;
}
