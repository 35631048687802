// ======================================================
// Abstracts :: Variables
// ======================================================

// Override FontAwesome path webfonts
$fa-font-path: '../fonts';

// Colors
// Modifier variables of Boostrap 4
// Detail: https://getbootstrap.com/docs/4.0/getting-started/theming/#variable-defaults
// Fonts
$font-family-base: 'Roboto', sans-serif;
$font-family-second: 'Lato', sans-serif;

$black: #091131;
$dark: #23253a;
$dark-mode: #313449;
$pinkish-grey: #bfbfbf;
$white: #fff;
$primary: #fb873e;
$primary-hover: #fff4f0;
$orange: #eb6c33;
$gray: #ccccd0;
$gray_01: #f6f6f6;
$gray_02: #f9f9f9;
$gray_03: #e1dfdf;
$cool-grey: #96979e;
$light: #f2f2f2;
$light_02: #fcfcfc;
$light_03: #dad9d9;
$slate-grey: #696a74;
$battleship-grey: #77777f;
$pinkish-grey-two: #bab6b6;
$steel: #8a8b93;
$gray: #c1bfbf;
$green: #76d595;
$default: #fafbfc;
$red: #eb3333;

// Paths
$path-to-images: '../assets/';

$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-medium: 500;

$font-size-extra-small: 10px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-larger: 24px;
$font-size-extra-larger: 36px;
