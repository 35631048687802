.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #F1F1F5;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc .fc-timegrid-slot {
  height: 50px;
}

.fc-timegrid-event .fc-event-main {
  padding: 6px 3px 4px;
}

.fc-v-event, .fc-h-event {
  border: none;
  background-color: transparent !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 2px;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 4px;
}

.fc .fc-daygrid-day-events {
  margin-top: 4px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 5px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 5px;
}

.fc .fc-daygrid-day.fc-day-today, .fc .fc-timegrid-col.fc-day-today {
  background-color: transparent;
}

.fc .fc-daygrid-day-number {
  padding: 0;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: white;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 4px;
  width: 25px;
  height: 25px;
  border-radius: 999px;
  background-color: #FF570D;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror {
  box-shadow: none;
}

.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: unset;
}

.fc-daygrid-dot-event:hover .custom-event-content {
  opacity: 0.75;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
  padding: 8px 8px 0;
}
