.searchExpand {
  padding: 20px;
  margin-top: 5px;
  display: none !important;
  font-size: 14px;
}

.show {
  display: block !important;
}

@media (max-width: 768px) {
  .caretPos {
    right: 17px;
    position: absolute;
    float: right;
    top: -22px;
    z-index: 4;
  }
  .inputGroupTextCustom {
    display: none !important;
  }
}

@media (min-width: 991px) {
  .caretPos {
    right: 17px;
    position: absolute;
    float: right;
    top: -22px;
    z-index: 4;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .caretPos {
    right: 17px;
    position: absolute;
    float: right;
    top: -22px;
    z-index: 4;
  }
}

.caretSize {
  border-top: 8px solid;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  cursor: pointer;
  border-radius: 4px;
}

.closeBtn {
  right: 25px;
  position: absolute;
  top: -12px;
  cursor: pointer;
  z-index: 1000;
}

.closeBtnHome {
  right: 20px;
  position: absolute;
  top: -35px;
  cursor: pointer;
  z-index: 1000;
}

.extraForm {
  padding: 10px;
  width: 100%;
}

.multiSearchBtn {
  float: right;
  margin: 0 20px 20px 0;
  font-size: 14px;
  width: 110px;
  height: 36px;
  border-radius: 12px !important;
  font-weight: bold !important;
}

.searchFloatContainerBtn {
  float: right;
  margin-right: 0 !important;
}

.searchBtnHover {
  background: #FE5810;
  color: #fff;
}

.formSearchTopWidth {
  max-width: 599px;
}

.inputGroupTextCustom {
  background-color: #f8f8f8 !important;
  border: none !important;
  padding: 0 5px 0 20px !important;
}

.formControlCustom {
  border-left: none !important;
  border: none !important;
  height: 44px !important;
  background-color: #f8f8f8 !important;
}

#inputSearch {
  /* border: 1px solid #ced4da; */
  border-radius: 5px;
  box-shadow: none;
	outline: none;
}

#inputSearch:focus-within {
  /* border: 1px solid #FE5810; */
  border-radius: 5px;
  box-shadow: none;
	outline: none;
}

.dropDownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0 0 5px 5px;
  border: 1px solid #f1f1f5;
}

.createFilterBtn {
  background: none;
  float: right;
  height: 36px;
  margin-right: 24px;
  color: #92929D;
  outline: none;
  cursor: pointer;
}
.createFilterBtn:hover {
  color: #212529;
}
