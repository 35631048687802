
.box-wrapper {
  border: 1px solid #F3F3F3;
  padding: 20px 30px !important;
  border-radius: 15px;
  margin-top: unset;
  padding-top: unset !important;
  overflow: hidden;
  border-top: 4px solid #61a3e2;;
}
.box-header {
  margin-top: 20px;
  font-weight: bold;
  font-size: 20px;
}

.box-border .box-item {
  list-style-type: disc !important;
}

.box-item-title {
  display: inline;
  font-weight: bold;
}

.box-item-highlight {
  color: #9dbee8 !important;
  cursor: pointer;
  display: inline;
  text-decoration: none;
}

.box-item-footer {
  display: flex;
  justify-content: flex-end;
}

.box-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
