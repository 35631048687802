.trend-container {
  border: 1px solid #F0F0F0;
  padding: 20px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  font-family: rubikMedium, sans-serif;

  .trend-title {
    font-size: 22px;
    padding-bottom: 20px;
    color: #344563;
  }

  .trend-content {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 360px;
  }

  .trend-item {
    margin-bottom: 18px;

    .trend-image {
      img {
        width: 100%;
        height: 90px;
        border-radius: 5px;

      }
    }

    .trend-detail {
      padding-left: 0px !important;
    }

    .trend-categtory {
      word-break: break-all;
      color: #70A1DD;
      font-size: 11px;
    }

    .trend-desc {
      word-break: break-all;
      color: #344563;
      font-size: 14px;
    }

    .trend-link {
      word-break: break-all;
      color: #92929D;
      font-size: 15px; 
      font-family: rubikRegular, sans-serif;
    }
  }

  .trend-item:last-child {
    margin-bottom: 0;
  }
}