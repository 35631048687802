// ======================================================
// Base :: Global
// ======================================================
body {
	font-family: rubikRegular, sans-serif
}

.rubikMedium {
	font-family: rubikMedium, sans-serif
}

.rubikBold {
	font-family: rubikBold, sans-serif
}

.poppinsSemiBold {
	font-family: poppinsSemiBold, sans-serif
}

.poppinsRegular {
	font-family: poppinsRegular, sans-serif
}

.poppinsMedium {
	font-family: poppinsMedium, sans-serif
}

.poppinsExtraBold {
	font-family: poppinsExtraBold, sans-serif
}

.poppinsBold {
	font-family: poppinsBold, sans-serif
}

.londrinaSolidregular {
	font-family: londrinaSolidregular, sans-serif
}

.abingdonRegular {
	font-family: abingdonRegular, sans-serif
}

.abingdonBold {
	font-family: abingdonBold, sans-serif
}

.robotoRegular {
	font-family: robotoRegular, sans-serif
}

.color_primary {
	color: #FE5810 !important
}

.color_white {
	color: #fff !important
}

.color_black {
	color: #000 !important
}

img {
	max-width: 100%
}

p {
	margin: 0
}

.mt25 {
	margin-top: 25px !important
}

.form-group {
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}




body {
	font-size: 14px;
	color: #696974;
	line-height: 1.65
  }
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: $white;
    @at-root .dark-mode #{&} {
      background: rgba(255, 255, 255, 0.05);
    }
  }
  ::-webkit-scrollbar-thumb {
    background: $gray_03;
    border-radius: 4px;
    @at-root .dark-mode #{&} {
      background: #e1dfdf;
    }
  }
  button {
    border: 0;
    outline: 0;
  }
  .main-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    25% {
      transform: scale(1.5);
      opacity: 0.75;
    }
    50% {
      transform: scale(2);
      opacity: 0.5;
    }
    75% {
      transform: scale(1.5);
      opacity: 0.75;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
 