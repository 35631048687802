// Here you can add other styles
.logo-admin {
    width: 87px;
    height: 35px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.header-admin {
    padding: 0px;
}

.col-pd-0 {
    padding: 0 !important;
}

.form-c {
  header {
    position: unset;
  }

  .card-header {
    background-color: #fff;
  }

  img.img-preview {
    margin-top: 20px;
    height: 200px;
  }

  .text-muted.field-error {
    color: red !important;
  }

  input.form-control:focus, textarea.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
}

.table-c {
  .card-header {
    position: unset;
    background-color: white;
  }

  .action-c {
    a, a:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

.c-wrapper {
    .btn {
        font-weight: 400;
        text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .btn-info {
        color: #fff;
        background-color: #39f;
        border-color: #39f;
      }
      
      .btn-info:hover {
        color: #fff;
        background-color: #0d86ff;
        border-color: #0080ff;
      }
      
      .btn-info:focus, .btn-info.focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      }
      
      .btn-info.disabled, .btn-info:disabled {
        color: #fff;
        background-color: #39f;
        border-color: #39f;
      }
      
      .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
      .show > .btn-info.dropdown-toggle {
        color: #fff;
        background-color: #117a8b;
        border-color: #10707f;
      }
      
      .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      }
      
  .btn-primary {
    color: #fff;
    background-color: #321fdb;
    border-color: #321fdb;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #2a1ab9;
    border-color: #2819ae;
  }

  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #2a1ab9;
    border-color: #2819ae;
    box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
  }

  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #321fdb;
    border-color: #321fdb;
  }

  .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2819ae;
    border-color: #2517a3;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
  }

  .btn-danger {
    color: #fff;
    background-color: #e55353;
    border-color: #e55353;
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #e03232;
    border-color: #de2727;
  }

  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #e03232;
    border-color: #de2727;
    box-shadow: 0 0 0 0.2rem rgba(233, 109, 109, 0.5);
  }

  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e55353;
    border-color: #e55353;
  }

  .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #de2727;
    border-color: #d82121;
  }

  .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 109, 109, 0.5);
  }

  .btn-secondary {
    color: #4f5d73;
    background-color: #ced2d8;
    border-color: #ced2d8;
  }

  .btn-secondary:hover {
    color: #4f5d73;
    background-color: #b9bec7;
    border-color: #b2b8c1;
  }

  .btn-secondary:focus, .btn-secondary.focus {
    color: #4f5d73;
    background-color: #b9bec7;
    border-color: #b2b8c1;
    box-shadow: 0 0 0 0.2rem rgba(187, 192, 201, 0.5);
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #4f5d73;
    background-color: #b2b8c1;
    border-color: #abb1bc;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 192, 201, 0.5);
  }
}

a.not-decoration, a:hover.not-decoration {
  text-decoration: none;
}

.f-right {
  float: right;
}
