.label-dropdown-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.label-dropdown-icon.btn {
  background: unset !important;
  background-color: unset !important;
}
.label-dropdown-icon.btn:hover {
  background: unset !important;
  background-color: unset !important;
}
.label-dropdown {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.label-dropdown-icon {
  border: unset !important;
  padding: 0 !important;
}

.label-dropdown-icon::after {
  content: none !important;
}

.label-dropdown-icon:focus {
  box-shadow: unset !important;
}

.label-dropdown-content-wrapper {
  width: 340px;
  color: #92929d;
  z-index: 999;
  background-color: white;
}

.label-dropdown-content-wrapper .label-dropdown-content-search {
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 1px solid grey;
  position: absolute;
  margin-right: 5px;
  padding-right: 26px;
}

.label-dropdown-wrapper {
  position: relative;
  height: 32px;
}

.label-dropdown-content-wrapper
  .label-dropdown-wrapper
  .label-dropdown-content-search:focus {
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 2px solid #fe5810;
}

.label-dropdown-wrapper .label-dropdown-search-icon {
  background-image: url("../../assets/images/search_black.png");
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  opacity: 0.8;
}

.label-dropdown-list-item {
  height: 200px;
  overflow-y: scroll;
  padding: 8px;
}

.label-dropdown-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 10px;
}

.label-dropdown-checkbox-wrapper:first-child {
  margin-top: 0;
}

.label-dropdown-checkbox {
  background-image: url("../../assets/images/check_box_outline_blank_black.png");
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.label-dropdown-checked {
  background-image: url("../../assets/images/check_box_black_checked.png");
  width: 20px;
  height: 20px;
}

.label-dropdown-tools {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #00000070;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
}

.label-dropdown-tools button {
  background-color: unset;
  text-align: left;
  cursor: pointer;
  color: #92929d;
}

.label-dropdown-tools button:hover {
  background-color: rgb(228, 228, 228);
}
