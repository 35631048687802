// ======================================================
// Components :: Collapse
// ======================================================

.collapse-custom {
  .collapse {
    opacity: 0;
    transition: all 0.4s ease-in-out;
    height: 0;
    transform: translateY(-50%);

    &.show {
      opacity: 1;
      transition: all 0.4s ease-in-out;
      height: auto;
      transform: translateY(0);
    }
  }
}

.btn-down-up {
  display: flex;
  padding: 5px 10px;

  .collapse-icon {
    svg {
      transform: rotate(180deg);
      transition: all 0.4s ease-in-out;
    }
  }
}
