.wrapper {
  padding-bottom: 20px;
}

.borderTop {
  border-top: 1px solid rgba(100, 121, 143, 0.122);
}

.wrapperCollapse {
  cursor: pointer;
}

.collapseBody {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-left: 7px;
  cursor: pointer;
}

.fromWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.info {
  font-size: 14px;
  font-weight: normal;
  color: #fe5810;
  position: relative;
  margin-left: 6px;
  font-family: rubikRegular, sans-serif;
}

.flexOption {
  display: flex;
  margin-right: 12px;
  text-decoration: none;
}

.flexOption li {
  margin-left: 20px;
}

.flexOption li a:hover {
  text-decoration: none;
}
