.SocialIcons {
    display: flex;
    column-gap: 20px;
    align-items: center;
    font-size: 20px;
}
.SocialIcons .Share {
    font-size: 14px;
    padding-right: 20px;
    border-right: 2px solid #696974;
    color: #696974;
    font-family: robotoRegular, sans-serif;
    line-height: 1;
    font-weight: lighter;
}
.SocialIcons .Icon {
    height: 13px;
    width: auto;
    color: #344563;
    font-size: 14px;
}
.SocialIcons .Icon:hover {
    opacity: .4;
}
div.progressBar {
    height: 5px;
    background-color: #e9ecef73;
}
div.progressBar .bsProgressBar {
    background-color: #dc3562 !important;
}

div.progressBar .bsProgressBarCategory {
  background-color: #2528BC !important;
}

.relatedArticles {
    width: 100%;
    margin-top: 40px;
}
.relatedArticlesTittle {
    padding-top: 15px;
    font-size: 14px;
    color: #dc3562;
    text-transform: uppercase;
    font-weight: bold;
}

.relatedArticlesTittleCategory {
  padding-top: 15px;
  font-size: 14px;
  color: #2528BC;
  text-transform: uppercase;
  font-weight: bold;
}

.relatedArticleItem {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    word-break: break-all;
}
.relatedArticleItem .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.75;
}
.relatedArticleItem .author {
    color: #fe5810;
    font-size: 14px;
    font-weight: 16px;
}
.socialIcon {
  font-size: 18px;
  color: #323643;
  margin-right: 10px;
  margin-left: 10px;
}

.iconWeather {
  color: #FFC542;
  font-size: 20px
}

.weatherInfo {
  margin-right: 10px;
  border-right: 1px solid #323643; 
  padding-right: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.groupInfo {
  display: flex;
  align-items: center;
  margin-top: 3px;
  justify-content: flex-end;
}

.groupInfo a {
  line-height: 1;
}

.groupInfo a:hover {
  text-decoration: none;
}

.groupInfo a .socialIcon:hover {
  color: #E0F3FB;
}

.groupHeader {
  width: 28%;
}

.newMainContent {
  display: block;
  margin-top: 30px;
}
@media (min-width: 992px) {
  .groupNavItems {
    width: 72% !important;
    margin-right: 0% !important;
  }

  .newMainContent {
    display: flex;
  }
  .leftSideNews {
    flex: 1;
  }
  .newsArticle {
    flex: 2;
  }
}

@media (max-width: 992px) {
  .groupNavItems {
    width: 100% !important;
  }
  .groupHeader {
    width: 100%;
  } 
  .groupInfo {
    justify-content: flex-start;
  }
  .iconWeather {
    margin-left: 0;
  }
}

.headerWrapper {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 0 !important;
}

