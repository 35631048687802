input.form-control,
textarea.form-control {
  border-color: #f1f1f5;
  border-radius: 5px;
  padding: 10px 15px;
}

input.form-control:focus,
textarea.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: #fe5810;
}

.dropdown .dropdown-menu {
  padding: 7px 0;
  margin-top: 8px;
  border: none;
  -moz-box-shadow: 0px 0px 10px 0 rgba(52, 69, 99, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0 rgba(52, 69, 99, 0.2);
  -ms-box-shadow: 0px 0px 10px 0 rgba(52, 69, 99, 0.2);
  -o-box-shadow: 0px 0px 10px 0 rgba(52, 69, 99, 0.2);
  box-shadow: 0px 0px 10px 0 rgba(52, 69, 99, 0.2);
}

.dropdown .dropdown-menu li + li {
  margin: 0 !important;
}

.dropdown .dropdown-menu li a {
  display: block;
  padding: 5px 10px;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}

.dropdown .dropdown-menu li a:hover {
  background: rgba(254, 88, 16, 0.1);
}

.dropdown .dropdown-toggle.no-after:after {
  display: none;
}

a {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #92929d;
}

a:hover {
  color: #fe5810;
}

.tabs .nav-tabs {
  border: none;
}

.tabs .nav-tabs .nav-link {
  border: none;
  margin: 0;
  border-radius: 0;
}

.tabs .nav-tabs .nav-link:hover,
.tabs .nav-tabs .nav-link.active {
  color: #fe5810;
}

.form-check {
  position: relative;
  padding: 0;
}

.form-check .form-check-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  margin: 0;
}

.form-check .form-check-input:checked + .form-check-label:before {
  opacity: 1;
}

.form-check .form-check-input:checked + .form-check-label:after {
  /* border-color: #fe5810; */
  border: 0 solid;
  background: #344563;
}

.form-check .form-check-label {
  position: relative;
  padding-left: 25px;
}

.form-check .form-check-label:after,
.form-check .form-check-label:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.form-check .form-check-label:before {
  content: "✓";
  font-size: 16px;
  color: #fff;
  left: 3px;
  z-index: 2;
  opacity: 0;
}

.form-check .form-check-label:after {
  width: 18px;
  height: 18px;
  border: 1px solid #92929d;
  border-radius: 2px;
  left: 0;
}

.hover_circle,
.hover_square {
  position: relative;
  cursor: pointer;
}

.hover_circle:before,
.hover_square:before {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background: rgba(254, 88, 16, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.hover_circle:hover:before,
.hover_square:hover:before {
  transform: translate(-50%, -50%) scale(1);
}

.hover_circle:before {
  border-radius: 50%;
}

.select2-container.select2-container--open .select2-dropdown {
  border-color: #ebebeb;
}

.select2-container.select2-container--open
  .select2-results__option[aria-selected="true"] {
  background-color: #fdfdfd;
}

.select2-container.select2-container--open
  .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(254, 88, 16, 0.1);
  color: #696974;
}

.list-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 0;
}

.list-flex li {
  position: relative;
  display: block;
  line-height: 1;
}

.list-flex li + li {
  margin-left: 20px;
}

.list-flex li a {
  font-size: 17px;
  display: block;
  line-height: 1;
}

.list-flex li a:hover {
  text-decoration: none;
}

.list-flex li input {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  right: 0;
  z-index: 5;
  opacity: 0;
  overflow: unset;
}

.list-flex li [class*="icon-"] {
  font-size: 20px;
}

.user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.user .avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.user .user-info {
  width: calc(100% - 60px);
}

.user .user-info p {
  margin: 0;
  color: #babac2;
}

.user .user-info p.user-name {
  color: #171725;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user .user-info p.user-name a {
  font-size: 14px;
  font-weight: normal;
  color: #fe5810;
  position: relative;
  margin-left: 6px;
  font-family: rubikRegular, sans-serif;
}

.user .user-info p.user-name a:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  background: #d8d8d8;
  border-radius: 50%;
  margin-right: 6px;
}

.user .user-info p.user-mail {
  font-size: 13px;
  color: #b5b5be;
}

.flag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.flag .flag-image {
  width: 25px;
  height: 17px;
  margin-right: 10px;
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: block;
}

.flag .flag-name {
  display: block;
  white-space: nowrap;
  width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.box-flex .flex-50 {
  width: 50%;
}

.txt-meta {
  color: #92929d;
}

.map-container {
  position: relative;
  height: 100%;
}

.map-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .map-container {
    padding-top: 70%;
  }
}

.wow {
  visibility: hidden;
}

@media (max-width: 991px) and (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 420px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .container .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .container .row [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.main-mail {
  padding-top: 77px;
}

footer {
  background: #323643;
  padding: 80px 0 70px;
  font-size: 14px;
}

footer .social-list li + li {
  margin-left: 15px;
}

footer .social-list li a {
  font-size: 17px;
  color: #fff;
  line-height: 0;
}

footer .social-list li a:hover {
  color: #fe5810;
}

footer .widget-title {
  font-size: 14px;
  color: #fff;
  margin-bottom: 18px;
}

footer .fnav {
  font-size: 14px;
  margin: 0;
}

footer .fnav li + li {
  margin-top: 10px;
}

footer .fnav li a {
  text-decoration: none;
}

footer .form-subcribe {
  position: relative;
  margin-bottom: 20px;
}

footer .form-subcribe .form-control {
  border-radius: 39px;
  height: 47px;
  background: #e2e2ea;
  border-color: transparent;
  font-size: 14px;
  color: #6c6c6c;
  padding: 15px 60px 15px 23px;
}

footer .form-subcribe .form-control::placeholder {
  color: #6c6c6c;
}

footer .form-subcribe .form-control:focus {
  border-color: #ff570d;
}

footer .form-subcribe .btn-search {
  padding: 0;
  border: none;
  width: 39px;
  height: 39px;
  line-height: 40px;
  border-radius: 50%;
  background: #ff570d;
  color: #fff;
  font-size: 17px;
  position: absolute;
  top: 4px;
  right: 4px;
}

footer .copyright {
  margin-top: 20px;
}

.mail-footer {
  padding: 30px 0;
}

.mail-footer ul {
  margin: 0;
}

.mail-footer .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.mail-footer .footer-nav-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.mail-footer .footer-nav-wrap .logo {
  width: 27px;
  height: 29px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.mail-footer .footer-nav-wrap .footer-nav li a {
  text-transform: uppercase;
  font-size: 14px;
}

@media (min-width: 768px) {
  .mail-footer .social-list {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  .mail-footer .footer-nav-wrap .logo {
    margin-right: 60px;
  }
  .mail-footer .footer-nav-wrap .footer-nav li + li {
    margin-left: 50px;
  }
}

@media (max-width: 991px) {
  .footer .social-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .footer .copyright {
    text-align: center;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .footer .fnav-widget-wrap {
    margin-bottom: 30px;
  }
  .mail-footer .footer-nav-wrap .logo {
    margin-right: 40px;
  }
  .mail-footer .footer-nav-wrap .footer-nav li + li {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  footer:not(.mail-footer) {
    padding: 40px 0;
  }
  footer .fnav-widget-wrap .widget {
    margin-bottom: 30px;
  }
  .mail-footer .footer-nav-wrap,
  .mail-footer .social-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .mail-footer .footer-nav-wrap {
    margin-bottom: 20px;
  }
  .mail-footer .footer-nav-wrap .logo {
    margin-right: 40px;
  }
}

@media (max-width: 480px) {
  .mail-footer .footer-nav-wrap .logo {
    margin: 0 0 20px;
  }
  .mail-footer .footer-nav-wrap .footer-nav {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .mail-footer .footer-nav-wrap .footer-nav li {
    margin: 5px;
  }
  .mail-footer .footer-nav-wrap .footer-nav li a {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .mail-footer .footer-nav-wrap .footer-nav {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

.content-wrap {
  position: relative;
  font-size: 14px;
  padding-bottom: 100px;
}

.main-content .list-menu {
  margin-bottom: 15px;
}

.main-content .list-menu > li {
  display: inline-block;
  margin: 5px 14px 5px 0;
}

.main-content .list-menu > li a {
  text-decoration: none;
}

.main-content .list-menu > li > a {
  color: #696974;
  text-transform: capitalize;
  color: #696974;
}

.main-content .list-menu > li > a [class*="icon-"] {
  margin-right: 5px;
  color: #b5b5be;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main-content .list-menu > li > a:hover,
.main-content .list-menu > li > a.active {
  color: #171725;
}

.main-content .list-menu > li > a:hover [class*="icon-"],
.main-content .list-menu > li > a.active [class*="icon-"] {
  color: #44444f;
}

.main-content .list-menu > li > a.active {
  font-family: rubikMedium, sans-serif;
}

.main-content .news-item-wrap {
  margin-bottom: 45px;
}

.main-content .news-items + .news-items {
  margin-top: 30px;
}

.main-content .news-items a {
  text-decoration: none;
  text-transform: capitalize;
  line-height: 1.4;
}

.main-content .news-items a:hover {
  color: #fe5810 !important;
}

.main-content .news-items .link a {
  text-transform: none;
}

.main-content .news-items .comment-total {
  margin-top: 8%;
}

.main-content .news-items .comment-total [class*="icon-"] {
  font-size: 24px;
  color: #ffc542;
  display: inline-block;
  vertical-align: middle;
  margin-right: 11px;
}

.main-content .news-items .thumbnail {
  overflow: hidden;
}

.main-content .news-items .thumbnail a {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.main-content .news-items .thumbnail a:hover {
  transform: scale(1.1);
}

.main-content .news-items .news-item + .news-item {
  margin-top: 15px;
}

.main-content .news-items .news-item.item-primary {
  background: #323643;
  color: #e2e2ea;
  border-radius: 12px;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.main-content .news-items .news-item.item-primary .thumbnail a {
  height: 290px;
}

.main-content .news-items .news-item.item-primary .item-text {
  padding: 15px 4%;
}

.main-content .news-items .news-item.item-primary .item-text a {
  color: #e2e2ea;
}

.main-content .news-items .news-item.item-primary .item-text h2 {
  margin-bottom: 15px;
  font-size: 22px;
}

.main-content .news-items .news-item.item-primary .item-text h2 a {
  color: #e2e2ea;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.main-content .news-items .news-item.item-primary ~ .news-item .item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  background: #fafafb;
}

.main-content .news-items .news-item > .item {
  width: calc((100% - 15px) / 2);
}

.main-content .news-items .news-item > .item + .item {
  margin-left: 15px;
}

.main-content .news-items .news-item > .items {
  width: calc((100% - 15px) / 2);
  margin-left: 15px;
}

.main-content .news-items .news-item > .items .item + .item {
  margin-top: 15px;
}

.main-content .news-items .news-item .item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.main-content .news-items .news-item .item .thumbnail {
  border-radius: 12px;
}

.main-content .news-items .news-item .item .thumbnail a {
  width: 130px;
  height: 89px;
  background-size: 100%;
  border-radius: 10px;
}

.main-content .news-items .news-item .item .box-text {
  width: calc(100% - 145px);
  margin-left: 15px;
  padding: 5px 15px 5px 0;
}

.main-content .news-items .news-item .item .box-text h4 {
  margin-bottom: 5px;
}

.main-content .news-items .news-item .item .box-text h4 a {
  display: block;
  color: #171725;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.main-content .news-items .news-item .item .box-text .link {
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-content .news-items .news-item .item:not(.item-position) h4 {
  font-size: 15px;
}

.main-content .news-items .news-item .item:not(.item-position) h4 a {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.main-content .news-items .news-item .item.item-position {
  border-radius: 12px;
  overflow: hidden;
}

.main-content .news-items .news-item .item.item-position .thumbnail {
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.main-content .news-items .news-item .item.item-position .thumbnail:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 75%;
  background-image: linear-gradient(to bottom, rgba(50, 54, 67, 0), #323643);
  left: 0;
  bottom: 0;
}

.main-content .news-items .news-item .item.item-position .category-tag {
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 3px;
  padding: 4px 8px 1px;
  background: #ff4242;
  color: #fff;
  font-size: 12px;
  min-width: 72px;
  text-align: center;
  margin: 0;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .category-tag.category-tag_finance {
  background: #ff4242;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .category-tag.category-tag_politics {
  background: #4c42ff;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .category-tag.category-tag_health {
  background: #ff8f42;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .category-tag.category-tag_sports {
  background: #52c3ff;
}

.main-content .news-items .news-item .item.item-position .box-text {
  position: absolute;
  width: 100%;
  color: #fff;
  margin: 0;
  left: 0;
  bottom: 10px;
  padding: 0 15px;
}

.main-content .news-items .news-item .item.item-position .box-text a:hover {
  color: #b5b5be !important;
}

.main-content .news-items .news-item .item.item-position .box-text h4 a {
  color: #fff;
}

.main-content .news-items .news-item .item.item-position .box-text ul {
  margin: 0;
}

.main-content .news-items .news-item .item.item-position .box-text .item-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .item-info
  a {
  color: #fff;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .item-info
  li.link {
  margin-right: 10px;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .item-info
  li.time {
  position: relative;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .item-info
  li.time:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 1px;
  height: 12px;
  border-left: 1px solid #fff;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .user-interactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  max-width: 180px;
  margin-top: 10px;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .user-interactive
  li
  + li {
  margin-left: 10px;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .user-interactive
  li
  [class*="icon-"] {
  margin-right: 10px;
}

.main-content
  .news-items
  .news-item
  .item.item-position
  .box-text
  .user-interactive
  li.comment
  [class*="icon-"] {
  font-size: 15px;
}

.main-content .news-items .news-item .item.item-position:hover .thumbnail {
  transform: scale(1.1);
}

.product-item-wrap .product-items {
  position: relative;
  color: #92929d;
}

.product-item-wrap .product-items + .product-items {
  margin-top: 50px;
}

.product-item-wrap .product-items .product-items_title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;
}

.product-item-wrap .product-items .product-items_title h3 {
  text-transform: capitalize;
  font-size: 18px;
  margin: 0 20px 0 0;
}

.product-item-wrap .product-items .product-items_title h3 [class*="icon-"] {
  margin-right: 8px;
  font-size: 22px;
  vertical-align: middle;
}

.product-item-wrap .product-items .product-items_title .tabs {
  flex: auto;
  text-align: right;
}

.product-item-wrap .product-items .product-items_title .tabs .nav-tabs {
  -webkit-flex-wrap: unset;
  -moz-flex-wrap: unset;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

.product-item-wrap
  .product-items
  .product-items_title
  .tabs
  .nav-tabs
  .nav-link {
  padding: 0;
  color: #92929d;
  font-size: 15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .tabs
  .nav-tabs
  .nav-link
  + .nav-link {
  margin-left: 6%;
}

.product-item-wrap
  .product-items
  .product-items_title
  .tabs
  .nav-tabs
  .nav-link:hover,
.product-item-wrap
  .product-items
  .product-items_title
  .tabs
  .nav-tabs
  .nav-link.active {
  color: #fe5810;
}

.product-item-wrap .product-items .product-items_title .category-tag-list {
  margin: 0;
  flex: auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

.product-item-wrap .product-items .product-items_title .category-tag-list li {
  position: relative;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(1) {
  z-index: 2;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(2) {
  z-index: 3;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(3) {
  z-index: 4;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(4) {
  z-index: 5;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(5) {
  z-index: 6;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(6) {
  z-index: 7;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(7) {
  z-index: 8;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(8) {
  z-index: 9;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(9) {
  z-index: 10;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(10) {
  z-index: 11;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(11) {
  z-index: 12;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(12) {
  z-index: 13;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(13) {
  z-index: 14;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(14) {
  z-index: 15;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(15) {
  z-index: 16;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(16) {
  z-index: 17;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(17) {
  z-index: 18;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(18) {
  z-index: 19;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(19) {
  z-index: 20;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(20) {
  z-index: 21;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(21) {
  z-index: 22;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(22) {
  z-index: 23;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(23) {
  z-index: 24;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(24) {
  z-index: 25;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(25) {
  z-index: 26;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(26) {
  z-index: 27;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(27) {
  z-index: 28;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(28) {
  z-index: 29;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(29) {
  z-index: 30;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(30) {
  z-index: 31;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(31) {
  z-index: 32;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(32) {
  z-index: 33;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(33) {
  z-index: 34;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(34) {
  z-index: 35;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(35) {
  z-index: 36;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(36) {
  z-index: 37;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(37) {
  z-index: 38;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(38) {
  z-index: 39;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(39) {
  z-index: 40;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(40) {
  z-index: 41;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(41) {
  z-index: 42;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(42) {
  z-index: 43;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(43) {
  z-index: 44;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(44) {
  z-index: 45;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(45) {
  z-index: 46;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(46) {
  z-index: 47;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(47) {
  z-index: 48;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(48) {
  z-index: 49;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(49) {
  z-index: 50;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(50) {
  z-index: 51;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(51) {
  z-index: 52;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(52) {
  z-index: 53;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(53) {
  z-index: 54;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(54) {
  z-index: 55;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(55) {
  z-index: 56;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(56) {
  z-index: 57;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(57) {
  z-index: 58;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(58) {
  z-index: 59;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(59) {
  z-index: 60;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(60) {
  z-index: 61;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(61) {
  z-index: 62;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(62) {
  z-index: 63;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(63) {
  z-index: 64;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(64) {
  z-index: 65;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(65) {
  z-index: 66;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(66) {
  z-index: 67;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(67) {
  z-index: 68;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(68) {
  z-index: 69;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(69) {
  z-index: 70;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(70) {
  z-index: 71;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(71) {
  z-index: 72;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(72) {
  z-index: 73;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(73) {
  z-index: 74;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(74) {
  z-index: 75;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(75) {
  z-index: 76;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(76) {
  z-index: 77;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(77) {
  z-index: 78;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(78) {
  z-index: 79;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(79) {
  z-index: 80;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(80) {
  z-index: 81;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(81) {
  z-index: 82;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(82) {
  z-index: 83;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(83) {
  z-index: 84;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(84) {
  z-index: 85;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(85) {
  z-index: 86;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(86) {
  z-index: 87;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(87) {
  z-index: 88;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(88) {
  z-index: 89;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(89) {
  z-index: 90;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(90) {
  z-index: 91;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(91) {
  z-index: 92;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(92) {
  z-index: 93;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(93) {
  z-index: 94;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(94) {
  z-index: 95;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(95) {
  z-index: 96;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(96) {
  z-index: 97;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(97) {
  z-index: 98;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(98) {
  z-index: 99;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(99) {
  z-index: 100;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(100) {
  z-index: 101;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(101) {
  z-index: 102;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(102) {
  z-index: 103;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(103) {
  z-index: 104;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(104) {
  z-index: 105;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(105) {
  z-index: 106;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(106) {
  z-index: 107;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(107) {
  z-index: 108;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(108) {
  z-index: 109;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(109) {
  z-index: 110;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(110) {
  z-index: 111;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(111) {
  z-index: 112;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(112) {
  z-index: 113;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(113) {
  z-index: 114;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(114) {
  z-index: 115;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(115) {
  z-index: 116;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(116) {
  z-index: 117;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(117) {
  z-index: 118;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(118) {
  z-index: 119;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(119) {
  z-index: 120;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(120) {
  z-index: 121;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(121) {
  z-index: 122;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(122) {
  z-index: 123;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(123) {
  z-index: 124;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(124) {
  z-index: 125;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(125) {
  z-index: 126;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(126) {
  z-index: 127;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(127) {
  z-index: 128;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(128) {
  z-index: 129;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(129) {
  z-index: 130;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(130) {
  z-index: 131;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(131) {
  z-index: 132;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(132) {
  z-index: 133;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(133) {
  z-index: 134;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(134) {
  z-index: 135;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(135) {
  z-index: 136;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(136) {
  z-index: 137;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(137) {
  z-index: 138;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(138) {
  z-index: 139;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(139) {
  z-index: 140;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(140) {
  z-index: 141;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(141) {
  z-index: 142;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(142) {
  z-index: 143;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(143) {
  z-index: 144;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(144) {
  z-index: 145;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(145) {
  z-index: 146;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(146) {
  z-index: 147;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(147) {
  z-index: 148;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(148) {
  z-index: 149;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(149) {
  z-index: 150;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(150) {
  z-index: 151;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(151) {
  z-index: 152;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(152) {
  z-index: 153;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(153) {
  z-index: 154;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(154) {
  z-index: 155;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(155) {
  z-index: 156;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(156) {
  z-index: 157;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(157) {
  z-index: 158;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(158) {
  z-index: 159;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(159) {
  z-index: 160;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(160) {
  z-index: 161;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(161) {
  z-index: 162;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(162) {
  z-index: 163;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(163) {
  z-index: 164;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(164) {
  z-index: 165;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(165) {
  z-index: 166;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(166) {
  z-index: 167;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(167) {
  z-index: 168;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(168) {
  z-index: 169;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(169) {
  z-index: 170;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(170) {
  z-index: 171;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(171) {
  z-index: 172;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(172) {
  z-index: 173;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(173) {
  z-index: 174;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(174) {
  z-index: 175;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(175) {
  z-index: 176;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(176) {
  z-index: 177;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(177) {
  z-index: 178;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(178) {
  z-index: 179;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(179) {
  z-index: 180;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(180) {
  z-index: 181;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(181) {
  z-index: 182;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(182) {
  z-index: 183;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(183) {
  z-index: 184;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(184) {
  z-index: 185;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(185) {
  z-index: 186;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(186) {
  z-index: 187;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(187) {
  z-index: 188;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(188) {
  z-index: 189;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(189) {
  z-index: 190;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(190) {
  z-index: 191;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(191) {
  z-index: 192;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(192) {
  z-index: 193;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(193) {
  z-index: 194;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(194) {
  z-index: 195;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(195) {
  z-index: 196;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(196) {
  z-index: 197;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(197) {
  z-index: 198;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(198) {
  z-index: 199;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(199) {
  z-index: 200;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(200) {
  z-index: 201;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(201) {
  z-index: 202;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(202) {
  z-index: 203;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(203) {
  z-index: 204;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(204) {
  z-index: 205;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(205) {
  z-index: 206;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(206) {
  z-index: 207;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(207) {
  z-index: 208;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(208) {
  z-index: 209;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(209) {
  z-index: 210;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(210) {
  z-index: 211;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(211) {
  z-index: 212;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(212) {
  z-index: 213;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(213) {
  z-index: 214;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(214) {
  z-index: 215;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(215) {
  z-index: 216;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(216) {
  z-index: 217;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(217) {
  z-index: 218;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(218) {
  z-index: 219;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(219) {
  z-index: 220;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(220) {
  z-index: 221;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(221) {
  z-index: 222;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(222) {
  z-index: 223;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(223) {
  z-index: 224;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(224) {
  z-index: 225;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(225) {
  z-index: 226;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(226) {
  z-index: 227;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(227) {
  z-index: 228;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(228) {
  z-index: 229;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(229) {
  z-index: 230;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(230) {
  z-index: 231;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(231) {
  z-index: 232;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(232) {
  z-index: 233;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(233) {
  z-index: 234;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(234) {
  z-index: 235;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(235) {
  z-index: 236;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(236) {
  z-index: 237;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(237) {
  z-index: 238;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(238) {
  z-index: 239;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(239) {
  z-index: 240;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(240) {
  z-index: 241;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(241) {
  z-index: 242;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(242) {
  z-index: 243;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(243) {
  z-index: 244;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(244) {
  z-index: 245;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(245) {
  z-index: 246;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(246) {
  z-index: 247;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(247) {
  z-index: 248;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(248) {
  z-index: 249;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(249) {
  z-index: 250;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(250) {
  z-index: 251;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(251) {
  z-index: 252;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(252) {
  z-index: 253;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(253) {
  z-index: 254;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(254) {
  z-index: 255;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(255) {
  z-index: 256;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(256) {
  z-index: 257;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(257) {
  z-index: 258;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(258) {
  z-index: 259;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(259) {
  z-index: 260;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(260) {
  z-index: 261;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(261) {
  z-index: 262;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(262) {
  z-index: 263;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(263) {
  z-index: 264;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(264) {
  z-index: 265;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(265) {
  z-index: 266;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(266) {
  z-index: 267;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(267) {
  z-index: 268;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(268) {
  z-index: 269;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(269) {
  z-index: 270;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(270) {
  z-index: 271;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(271) {
  z-index: 272;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(272) {
  z-index: 273;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(273) {
  z-index: 274;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(274) {
  z-index: 275;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(275) {
  z-index: 276;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(276) {
  z-index: 277;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(277) {
  z-index: 278;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(278) {
  z-index: 279;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(279) {
  z-index: 280;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(280) {
  z-index: 281;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(281) {
  z-index: 282;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(282) {
  z-index: 283;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(283) {
  z-index: 284;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(284) {
  z-index: 285;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(285) {
  z-index: 286;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(286) {
  z-index: 287;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(287) {
  z-index: 288;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(288) {
  z-index: 289;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(289) {
  z-index: 290;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(290) {
  z-index: 291;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(291) {
  z-index: 292;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(292) {
  z-index: 293;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(293) {
  z-index: 294;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(294) {
  z-index: 295;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(295) {
  z-index: 296;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(296) {
  z-index: 297;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(297) {
  z-index: 298;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(298) {
  z-index: 299;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(299) {
  z-index: 300;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(300) {
  z-index: 301;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(301) {
  z-index: 302;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(302) {
  z-index: 303;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(303) {
  z-index: 304;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(304) {
  z-index: 305;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(305) {
  z-index: 306;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(306) {
  z-index: 307;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(307) {
  z-index: 308;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(308) {
  z-index: 309;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(309) {
  z-index: 310;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(310) {
  z-index: 311;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(311) {
  z-index: 312;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(312) {
  z-index: 313;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(313) {
  z-index: 314;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(314) {
  z-index: 315;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(315) {
  z-index: 316;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(316) {
  z-index: 317;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(317) {
  z-index: 318;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(318) {
  z-index: 319;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(319) {
  z-index: 320;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(320) {
  z-index: 321;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(321) {
  z-index: 322;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(322) {
  z-index: 323;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(323) {
  z-index: 324;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(324) {
  z-index: 325;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(325) {
  z-index: 326;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(326) {
  z-index: 327;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(327) {
  z-index: 328;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(328) {
  z-index: 329;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(329) {
  z-index: 330;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(330) {
  z-index: 331;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(331) {
  z-index: 332;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(332) {
  z-index: 333;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(333) {
  z-index: 334;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(334) {
  z-index: 335;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(335) {
  z-index: 336;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(336) {
  z-index: 337;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(337) {
  z-index: 338;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(338) {
  z-index: 339;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(339) {
  z-index: 340;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(340) {
  z-index: 341;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(341) {
  z-index: 342;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(342) {
  z-index: 343;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(343) {
  z-index: 344;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(344) {
  z-index: 345;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(345) {
  z-index: 346;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(346) {
  z-index: 347;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(347) {
  z-index: 348;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(348) {
  z-index: 349;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(349) {
  z-index: 350;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(350) {
  z-index: 351;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(351) {
  z-index: 352;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(352) {
  z-index: 353;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(353) {
  z-index: 354;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(354) {
  z-index: 355;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(355) {
  z-index: 356;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(356) {
  z-index: 357;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(357) {
  z-index: 358;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(358) {
  z-index: 359;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(359) {
  z-index: 360;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(360) {
  z-index: 361;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(361) {
  z-index: 362;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(362) {
  z-index: 363;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(363) {
  z-index: 364;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(364) {
  z-index: 365;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(365) {
  z-index: 366;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(366) {
  z-index: 367;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(367) {
  z-index: 368;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(368) {
  z-index: 369;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(369) {
  z-index: 370;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(370) {
  z-index: 371;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(371) {
  z-index: 372;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(372) {
  z-index: 373;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(373) {
  z-index: 374;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(374) {
  z-index: 375;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(375) {
  z-index: 376;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(376) {
  z-index: 377;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(377) {
  z-index: 378;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(378) {
  z-index: 379;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(379) {
  z-index: 380;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(380) {
  z-index: 381;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(381) {
  z-index: 382;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(382) {
  z-index: 383;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(383) {
  z-index: 384;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(384) {
  z-index: 385;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(385) {
  z-index: 386;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(386) {
  z-index: 387;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(387) {
  z-index: 388;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(388) {
  z-index: 389;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(389) {
  z-index: 390;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(390) {
  z-index: 391;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(391) {
  z-index: 392;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(392) {
  z-index: 393;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(393) {
  z-index: 394;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(394) {
  z-index: 395;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(395) {
  z-index: 396;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(396) {
  z-index: 397;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(397) {
  z-index: 398;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(398) {
  z-index: 399;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(399) {
  z-index: 400;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(400) {
  z-index: 401;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(401) {
  z-index: 402;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(402) {
  z-index: 403;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(403) {
  z-index: 404;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(404) {
  z-index: 405;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(405) {
  z-index: 406;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(406) {
  z-index: 407;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(407) {
  z-index: 408;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(408) {
  z-index: 409;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(409) {
  z-index: 410;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(410) {
  z-index: 411;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(411) {
  z-index: 412;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(412) {
  z-index: 413;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(413) {
  z-index: 414;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(414) {
  z-index: 415;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(415) {
  z-index: 416;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(416) {
  z-index: 417;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(417) {
  z-index: 418;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(418) {
  z-index: 419;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(419) {
  z-index: 420;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(420) {
  z-index: 421;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(421) {
  z-index: 422;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(422) {
  z-index: 423;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(423) {
  z-index: 424;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(424) {
  z-index: 425;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(425) {
  z-index: 426;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(426) {
  z-index: 427;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(427) {
  z-index: 428;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(428) {
  z-index: 429;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(429) {
  z-index: 430;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(430) {
  z-index: 431;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(431) {
  z-index: 432;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(432) {
  z-index: 433;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(433) {
  z-index: 434;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(434) {
  z-index: 435;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(435) {
  z-index: 436;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(436) {
  z-index: 437;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(437) {
  z-index: 438;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(438) {
  z-index: 439;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(439) {
  z-index: 440;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(440) {
  z-index: 441;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(441) {
  z-index: 442;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(442) {
  z-index: 443;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(443) {
  z-index: 444;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(444) {
  z-index: 445;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(445) {
  z-index: 446;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(446) {
  z-index: 447;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(447) {
  z-index: 448;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(448) {
  z-index: 449;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(449) {
  z-index: 450;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(450) {
  z-index: 451;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(451) {
  z-index: 452;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(452) {
  z-index: 453;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(453) {
  z-index: 454;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(454) {
  z-index: 455;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(455) {
  z-index: 456;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(456) {
  z-index: 457;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(457) {
  z-index: 458;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(458) {
  z-index: 459;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(459) {
  z-index: 460;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(460) {
  z-index: 461;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(461) {
  z-index: 462;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(462) {
  z-index: 463;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(463) {
  z-index: 464;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(464) {
  z-index: 465;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(465) {
  z-index: 466;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(466) {
  z-index: 467;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(467) {
  z-index: 468;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(468) {
  z-index: 469;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(469) {
  z-index: 470;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(470) {
  z-index: 471;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(471) {
  z-index: 472;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(472) {
  z-index: 473;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(473) {
  z-index: 474;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(474) {
  z-index: 475;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(475) {
  z-index: 476;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(476) {
  z-index: 477;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(477) {
  z-index: 478;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(478) {
  z-index: 479;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(479) {
  z-index: 480;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(480) {
  z-index: 481;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(481) {
  z-index: 482;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(482) {
  z-index: 483;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(483) {
  z-index: 484;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(484) {
  z-index: 485;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(485) {
  z-index: 486;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(486) {
  z-index: 487;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(487) {
  z-index: 488;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(488) {
  z-index: 489;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(489) {
  z-index: 490;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(490) {
  z-index: 491;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(491) {
  z-index: 492;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(492) {
  z-index: 493;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(493) {
  z-index: 494;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(494) {
  z-index: 495;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(495) {
  z-index: 496;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(496) {
  z-index: 497;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(497) {
  z-index: 498;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(498) {
  z-index: 499;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(499) {
  z-index: 500;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(500) {
  z-index: 501;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(501) {
  z-index: 502;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(502) {
  z-index: 503;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(503) {
  z-index: 504;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(504) {
  z-index: 505;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(505) {
  z-index: 506;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(506) {
  z-index: 507;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(507) {
  z-index: 508;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(508) {
  z-index: 509;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(509) {
  z-index: 510;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(510) {
  z-index: 511;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(511) {
  z-index: 512;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(512) {
  z-index: 513;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(513) {
  z-index: 514;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(514) {
  z-index: 515;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(515) {
  z-index: 516;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(516) {
  z-index: 517;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(517) {
  z-index: 518;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(518) {
  z-index: 519;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(519) {
  z-index: 520;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(520) {
  z-index: 521;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(521) {
  z-index: 522;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(522) {
  z-index: 523;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(523) {
  z-index: 524;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(524) {
  z-index: 525;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(525) {
  z-index: 526;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(526) {
  z-index: 527;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(527) {
  z-index: 528;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(528) {
  z-index: 529;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(529) {
  z-index: 530;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(530) {
  z-index: 531;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(531) {
  z-index: 532;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(532) {
  z-index: 533;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(533) {
  z-index: 534;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(534) {
  z-index: 535;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(535) {
  z-index: 536;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(536) {
  z-index: 537;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(537) {
  z-index: 538;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(538) {
  z-index: 539;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(539) {
  z-index: 540;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(540) {
  z-index: 541;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(541) {
  z-index: 542;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(542) {
  z-index: 543;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(543) {
  z-index: 544;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(544) {
  z-index: 545;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(545) {
  z-index: 546;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(546) {
  z-index: 547;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(547) {
  z-index: 548;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(548) {
  z-index: 549;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(549) {
  z-index: 550;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(550) {
  z-index: 551;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(551) {
  z-index: 552;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(552) {
  z-index: 553;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(553) {
  z-index: 554;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(554) {
  z-index: 555;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(555) {
  z-index: 556;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(556) {
  z-index: 557;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(557) {
  z-index: 558;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(558) {
  z-index: 559;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(559) {
  z-index: 560;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(560) {
  z-index: 561;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(561) {
  z-index: 562;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(562) {
  z-index: 563;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(563) {
  z-index: 564;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(564) {
  z-index: 565;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(565) {
  z-index: 566;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(566) {
  z-index: 567;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(567) {
  z-index: 568;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(568) {
  z-index: 569;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(569) {
  z-index: 570;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(570) {
  z-index: 571;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(571) {
  z-index: 572;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(572) {
  z-index: 573;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(573) {
  z-index: 574;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(574) {
  z-index: 575;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(575) {
  z-index: 576;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(576) {
  z-index: 577;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(577) {
  z-index: 578;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(578) {
  z-index: 579;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(579) {
  z-index: 580;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(580) {
  z-index: 581;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(581) {
  z-index: 582;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(582) {
  z-index: 583;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(583) {
  z-index: 584;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(584) {
  z-index: 585;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(585) {
  z-index: 586;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(586) {
  z-index: 587;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(587) {
  z-index: 588;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(588) {
  z-index: 589;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(589) {
  z-index: 590;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(590) {
  z-index: 591;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(591) {
  z-index: 592;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(592) {
  z-index: 593;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(593) {
  z-index: 594;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(594) {
  z-index: 595;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(595) {
  z-index: 596;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(596) {
  z-index: 597;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(597) {
  z-index: 598;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(598) {
  z-index: 599;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(599) {
  z-index: 600;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(600) {
  z-index: 601;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(601) {
  z-index: 602;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(602) {
  z-index: 603;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(603) {
  z-index: 604;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(604) {
  z-index: 605;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(605) {
  z-index: 606;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(606) {
  z-index: 607;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(607) {
  z-index: 608;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(608) {
  z-index: 609;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(609) {
  z-index: 610;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(610) {
  z-index: 611;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(611) {
  z-index: 612;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(612) {
  z-index: 613;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(613) {
  z-index: 614;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(614) {
  z-index: 615;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(615) {
  z-index: 616;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(616) {
  z-index: 617;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(617) {
  z-index: 618;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(618) {
  z-index: 619;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(619) {
  z-index: 620;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(620) {
  z-index: 621;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(621) {
  z-index: 622;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(622) {
  z-index: 623;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(623) {
  z-index: 624;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(624) {
  z-index: 625;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(625) {
  z-index: 626;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(626) {
  z-index: 627;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(627) {
  z-index: 628;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(628) {
  z-index: 629;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(629) {
  z-index: 630;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(630) {
  z-index: 631;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(631) {
  z-index: 632;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(632) {
  z-index: 633;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(633) {
  z-index: 634;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(634) {
  z-index: 635;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(635) {
  z-index: 636;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(636) {
  z-index: 637;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(637) {
  z-index: 638;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(638) {
  z-index: 639;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(639) {
  z-index: 640;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(640) {
  z-index: 641;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(641) {
  z-index: 642;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(642) {
  z-index: 643;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(643) {
  z-index: 644;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(644) {
  z-index: 645;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(645) {
  z-index: 646;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(646) {
  z-index: 647;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(647) {
  z-index: 648;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(648) {
  z-index: 649;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(649) {
  z-index: 650;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(650) {
  z-index: 651;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(651) {
  z-index: 652;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(652) {
  z-index: 653;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(653) {
  z-index: 654;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(654) {
  z-index: 655;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(655) {
  z-index: 656;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(656) {
  z-index: 657;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(657) {
  z-index: 658;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(658) {
  z-index: 659;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(659) {
  z-index: 660;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(660) {
  z-index: 661;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(661) {
  z-index: 662;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(662) {
  z-index: 663;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(663) {
  z-index: 664;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(664) {
  z-index: 665;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(665) {
  z-index: 666;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(666) {
  z-index: 667;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(667) {
  z-index: 668;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(668) {
  z-index: 669;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(669) {
  z-index: 670;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(670) {
  z-index: 671;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(671) {
  z-index: 672;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(672) {
  z-index: 673;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(673) {
  z-index: 674;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(674) {
  z-index: 675;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(675) {
  z-index: 676;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(676) {
  z-index: 677;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(677) {
  z-index: 678;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(678) {
  z-index: 679;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(679) {
  z-index: 680;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(680) {
  z-index: 681;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(681) {
  z-index: 682;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(682) {
  z-index: 683;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(683) {
  z-index: 684;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(684) {
  z-index: 685;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(685) {
  z-index: 686;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(686) {
  z-index: 687;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(687) {
  z-index: 688;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(688) {
  z-index: 689;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(689) {
  z-index: 690;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(690) {
  z-index: 691;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(691) {
  z-index: 692;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(692) {
  z-index: 693;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(693) {
  z-index: 694;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(694) {
  z-index: 695;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(695) {
  z-index: 696;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(696) {
  z-index: 697;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(697) {
  z-index: 698;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(698) {
  z-index: 699;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(699) {
  z-index: 700;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(700) {
  z-index: 701;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(701) {
  z-index: 702;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(702) {
  z-index: 703;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(703) {
  z-index: 704;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(704) {
  z-index: 705;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(705) {
  z-index: 706;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(706) {
  z-index: 707;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(707) {
  z-index: 708;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(708) {
  z-index: 709;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(709) {
  z-index: 710;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(710) {
  z-index: 711;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(711) {
  z-index: 712;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(712) {
  z-index: 713;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(713) {
  z-index: 714;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(714) {
  z-index: 715;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(715) {
  z-index: 716;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(716) {
  z-index: 717;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(717) {
  z-index: 718;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(718) {
  z-index: 719;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(719) {
  z-index: 720;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(720) {
  z-index: 721;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(721) {
  z-index: 722;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(722) {
  z-index: 723;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(723) {
  z-index: 724;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(724) {
  z-index: 725;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(725) {
  z-index: 726;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(726) {
  z-index: 727;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(727) {
  z-index: 728;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(728) {
  z-index: 729;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(729) {
  z-index: 730;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(730) {
  z-index: 731;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(731) {
  z-index: 732;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(732) {
  z-index: 733;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(733) {
  z-index: 734;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(734) {
  z-index: 735;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(735) {
  z-index: 736;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(736) {
  z-index: 737;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(737) {
  z-index: 738;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(738) {
  z-index: 739;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(739) {
  z-index: 740;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(740) {
  z-index: 741;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(741) {
  z-index: 742;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(742) {
  z-index: 743;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(743) {
  z-index: 744;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(744) {
  z-index: 745;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(745) {
  z-index: 746;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(746) {
  z-index: 747;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(747) {
  z-index: 748;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(748) {
  z-index: 749;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(749) {
  z-index: 750;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(750) {
  z-index: 751;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(751) {
  z-index: 752;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(752) {
  z-index: 753;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(753) {
  z-index: 754;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(754) {
  z-index: 755;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(755) {
  z-index: 756;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(756) {
  z-index: 757;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(757) {
  z-index: 758;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(758) {
  z-index: 759;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(759) {
  z-index: 760;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(760) {
  z-index: 761;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(761) {
  z-index: 762;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(762) {
  z-index: 763;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(763) {
  z-index: 764;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(764) {
  z-index: 765;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(765) {
  z-index: 766;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(766) {
  z-index: 767;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(767) {
  z-index: 768;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(768) {
  z-index: 769;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(769) {
  z-index: 770;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(770) {
  z-index: 771;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(771) {
  z-index: 772;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(772) {
  z-index: 773;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(773) {
  z-index: 774;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(774) {
  z-index: 775;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(775) {
  z-index: 776;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(776) {
  z-index: 777;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(777) {
  z-index: 778;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(778) {
  z-index: 779;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(779) {
  z-index: 780;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(780) {
  z-index: 781;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(781) {
  z-index: 782;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(782) {
  z-index: 783;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(783) {
  z-index: 784;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(784) {
  z-index: 785;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(785) {
  z-index: 786;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(786) {
  z-index: 787;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(787) {
  z-index: 788;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(788) {
  z-index: 789;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(789) {
  z-index: 790;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(790) {
  z-index: 791;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(791) {
  z-index: 792;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(792) {
  z-index: 793;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(793) {
  z-index: 794;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(794) {
  z-index: 795;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(795) {
  z-index: 796;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(796) {
  z-index: 797;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(797) {
  z-index: 798;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(798) {
  z-index: 799;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(799) {
  z-index: 800;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(800) {
  z-index: 801;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(801) {
  z-index: 802;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(802) {
  z-index: 803;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(803) {
  z-index: 804;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(804) {
  z-index: 805;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(805) {
  z-index: 806;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(806) {
  z-index: 807;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(807) {
  z-index: 808;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(808) {
  z-index: 809;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(809) {
  z-index: 810;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(810) {
  z-index: 811;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(811) {
  z-index: 812;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(812) {
  z-index: 813;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(813) {
  z-index: 814;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(814) {
  z-index: 815;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(815) {
  z-index: 816;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(816) {
  z-index: 817;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(817) {
  z-index: 818;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(818) {
  z-index: 819;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(819) {
  z-index: 820;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(820) {
  z-index: 821;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(821) {
  z-index: 822;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(822) {
  z-index: 823;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(823) {
  z-index: 824;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(824) {
  z-index: 825;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(825) {
  z-index: 826;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(826) {
  z-index: 827;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(827) {
  z-index: 828;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(828) {
  z-index: 829;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(829) {
  z-index: 830;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(830) {
  z-index: 831;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(831) {
  z-index: 832;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(832) {
  z-index: 833;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(833) {
  z-index: 834;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(834) {
  z-index: 835;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(835) {
  z-index: 836;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(836) {
  z-index: 837;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(837) {
  z-index: 838;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(838) {
  z-index: 839;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(839) {
  z-index: 840;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(840) {
  z-index: 841;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(841) {
  z-index: 842;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(842) {
  z-index: 843;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(843) {
  z-index: 844;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(844) {
  z-index: 845;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(845) {
  z-index: 846;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(846) {
  z-index: 847;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(847) {
  z-index: 848;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(848) {
  z-index: 849;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(849) {
  z-index: 850;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(850) {
  z-index: 851;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(851) {
  z-index: 852;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(852) {
  z-index: 853;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(853) {
  z-index: 854;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(854) {
  z-index: 855;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(855) {
  z-index: 856;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(856) {
  z-index: 857;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(857) {
  z-index: 858;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(858) {
  z-index: 859;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(859) {
  z-index: 860;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(860) {
  z-index: 861;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(861) {
  z-index: 862;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(862) {
  z-index: 863;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(863) {
  z-index: 864;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(864) {
  z-index: 865;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(865) {
  z-index: 866;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(866) {
  z-index: 867;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(867) {
  z-index: 868;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(868) {
  z-index: 869;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(869) {
  z-index: 870;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(870) {
  z-index: 871;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(871) {
  z-index: 872;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(872) {
  z-index: 873;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(873) {
  z-index: 874;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(874) {
  z-index: 875;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(875) {
  z-index: 876;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(876) {
  z-index: 877;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(877) {
  z-index: 878;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(878) {
  z-index: 879;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(879) {
  z-index: 880;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(880) {
  z-index: 881;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(881) {
  z-index: 882;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(882) {
  z-index: 883;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(883) {
  z-index: 884;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(884) {
  z-index: 885;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(885) {
  z-index: 886;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(886) {
  z-index: 887;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(887) {
  z-index: 888;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(888) {
  z-index: 889;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(889) {
  z-index: 890;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(890) {
  z-index: 891;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(891) {
  z-index: 892;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(892) {
  z-index: 893;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(893) {
  z-index: 894;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(894) {
  z-index: 895;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(895) {
  z-index: 896;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(896) {
  z-index: 897;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(897) {
  z-index: 898;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(898) {
  z-index: 899;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(899) {
  z-index: 900;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(900) {
  z-index: 901;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(901) {
  z-index: 902;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(902) {
  z-index: 903;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(903) {
  z-index: 904;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(904) {
  z-index: 905;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(905) {
  z-index: 906;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(906) {
  z-index: 907;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(907) {
  z-index: 908;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(908) {
  z-index: 909;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(909) {
  z-index: 910;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(910) {
  z-index: 911;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(911) {
  z-index: 912;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(912) {
  z-index: 913;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(913) {
  z-index: 914;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(914) {
  z-index: 915;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(915) {
  z-index: 916;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(916) {
  z-index: 917;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(917) {
  z-index: 918;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(918) {
  z-index: 919;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(919) {
  z-index: 920;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(920) {
  z-index: 921;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(921) {
  z-index: 922;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(922) {
  z-index: 923;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(923) {
  z-index: 924;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(924) {
  z-index: 925;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(925) {
  z-index: 926;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(926) {
  z-index: 927;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(927) {
  z-index: 928;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(928) {
  z-index: 929;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(929) {
  z-index: 930;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(930) {
  z-index: 931;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(931) {
  z-index: 932;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(932) {
  z-index: 933;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(933) {
  z-index: 934;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(934) {
  z-index: 935;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(935) {
  z-index: 936;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(936) {
  z-index: 937;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(937) {
  z-index: 938;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(938) {
  z-index: 939;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(939) {
  z-index: 940;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(940) {
  z-index: 941;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(941) {
  z-index: 942;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(942) {
  z-index: 943;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(943) {
  z-index: 944;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(944) {
  z-index: 945;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(945) {
  z-index: 946;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(946) {
  z-index: 947;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(947) {
  z-index: 948;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(948) {
  z-index: 949;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(949) {
  z-index: 950;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(950) {
  z-index: 951;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(951) {
  z-index: 952;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(952) {
  z-index: 953;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(953) {
  z-index: 954;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(954) {
  z-index: 955;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(955) {
  z-index: 956;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(956) {
  z-index: 957;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(957) {
  z-index: 958;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(958) {
  z-index: 959;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(959) {
  z-index: 960;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(960) {
  z-index: 961;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(961) {
  z-index: 962;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(962) {
  z-index: 963;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(963) {
  z-index: 964;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(964) {
  z-index: 965;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(965) {
  z-index: 966;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(966) {
  z-index: 967;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(967) {
  z-index: 968;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(968) {
  z-index: 969;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(969) {
  z-index: 970;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(970) {
  z-index: 971;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(971) {
  z-index: 972;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(972) {
  z-index: 973;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(973) {
  z-index: 974;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(974) {
  z-index: 975;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(975) {
  z-index: 976;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(976) {
  z-index: 977;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(977) {
  z-index: 978;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(978) {
  z-index: 979;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(979) {
  z-index: 980;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(980) {
  z-index: 981;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(981) {
  z-index: 982;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(982) {
  z-index: 983;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(983) {
  z-index: 984;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(984) {
  z-index: 985;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(985) {
  z-index: 986;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(986) {
  z-index: 987;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(987) {
  z-index: 988;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(988) {
  z-index: 989;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(989) {
  z-index: 990;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(990) {
  z-index: 991;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(991) {
  z-index: 992;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(992) {
  z-index: 993;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(993) {
  z-index: 994;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(994) {
  z-index: 995;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(995) {
  z-index: 996;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(996) {
  z-index: 997;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(997) {
  z-index: 998;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(998) {
  z-index: 999;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(999) {
  z-index: 1000;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-child(1000) {
  z-index: 1001;
  margin-right: -15px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li:nth-last-child(2) {
  margin-right: -8px !important;
}

.product-item-wrap .product-items .product-items_title .category-tag-list li a {
  display: block;
  white-space: nowrap;
  text-transform: capitalize;
  border: 1px solid #ececec;
  color: #323643;
  background: #fff;
  padding: 3px 12px;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li.show-all {
  z-index: 1;
  margin-right: 0 !important;
  margin-left: 20px;
}

.product-item-wrap
  .product-items
  .product-items_title
  .category-tag-list
  li.show-all
  a {
  border-color: rgba(255, 87, 13, 0.3);
  background: rgba(255, 87, 13, 0.1);
  border-radius: 5px;
  color: #ff570d;
}

.product-item-wrap .product-items .thumbnail {
  border-radius: 10px;
  overflow: hidden;
}

.product-item-wrap .product-items .thumbnail a {
  display: block;
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.product-item-wrap .product-items .thumbnail a:hover {
  transform: scale(1.1);
}

.product-item-wrap .product-items .btn {
  display: block;
  width: 100%;
  text-transform: capitalize;
  font-size: 12px;
  padding: 6px 15px;
  letter-spacing: 1px;
}

.product-item-wrap .product-items .btn.btn-primary {
  color: #fff !important;
}

.product-item-wrap .product-items .show-product-list {
  padding-top: 5px;
}

.product-item-wrap .product-items .show-product-list .product-list-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.product-item-wrap
  .product-items
  .show-product-list
  .product-list-item
  + .product-list-item {
  margin-top: 14px;
  padding-top: 14px;
  border-top: 1px solid #f0f0f6;
}

.product-item-wrap
  .product-items
  .show-product-list
  .product-list-item
  .thumbnail {
  width: 56px;
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .show-product-list
  .product-list-item
  .thumbnail
  a {
  height: 56px;
  background-size: 100%;
}

.product-item-wrap
  .product-items
  .show-product-list
  .product-list-item
  .product-txt {
  width: calc(100% - 66px);
}

.product-item-wrap
  .product-items
  .show-product-list
  .product-list-item
  .product-txt
  h4 {
  margin-bottom: 5px;
}

.product-item-wrap .product-items .show-product-list .show-more {
  margin-top: 22px;
}

.product-item-wrap
  .product-items
  .product-item-highlight
  .product-item-highlight_info
  .thumbnail {
  border: 1px solid #e6e6ea;
  padding: 5px;
}

.product-item-wrap
  .product-items
  .product-item-highlight
  .product-item-highlight_info
  .thumbnail
  a {
  background-size: cover;
}

.product-item-wrap
  .product-items
  .product-item-highlight
  .product-item-highlight_info
  h4 {
  font-size: 15px;
}

.product-item-wrap .product-items .txt-address {
  margin: 0 0 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-item-wrap .product-items .product-item_info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 0;
}

.product-item-wrap .product-items .product-item_info li {
  position: relative;
}

.product-item-wrap .product-items .product-item_info li:first-child:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #d8d8d8;
  margin-left: 8px;
}

.product-item-wrap .product-items .product-item_info li.cart:after {
  display: none;
}

.product-item-wrap .product-items .product-item_info li:not(:last-child) {
  margin-right: 8px;
}

.product-item-wrap .product-items .product-item_info li a {
  color: #fe5810;
}

.product-item-wrap .product-items h4 {
  text-transform: capitalize;
}

.product-item-wrap .product-items h4 a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-item-wrap .product-items a:not(:hover) {
  color: #323643;
}

.product-item-wrap .product-items a.desc-title:not(:hover) {
  color: #92929d;
  font-size: 13px;
  font-family: rubikRegular, sans-serif;
}

.product-item-wrap .product-items a:hover {
  text-decoration: none;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-items_title {
  padding-right: 5%;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6ea;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-items_title
  h3 {
  color: #70a1dd;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-items_title
  .tabs
  .nav-tabs
  .nav-link:hover,
.product-item-wrap
  .product-items.product-item_proclamation
  .product-items_title
  .tabs
  .nav-tabs
  .nav-link.active {
  color: #70a1dd;
}

.product-item-wrap .product-items.product-item_proclamation .show-product {
  width: calc((100% - 15px) / 2.8);
  border-width: 4px 1px 1px 1px;
  border-color: #70a1dd #e6e6ea #e6e6ea #e6e6ea;
  border-radius: 5px 5px 10px 10px;
  border-style: solid;
  padding: 15px 10px 15px;
  background: #fdfdff;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .show-product:not(.show-product-location) {
  width: calc((100% - 15px) / 2.8 * 1.8);
  margin-right: 15px;
}

.product-item-wrap .product-items.product-item_proclamation .flex-50 {
  width: calc((100% - 15px) / 2);
}

.product-item-wrap
  .product-items.product-item_proclamation
  .flex-50
  + .flex-50 {
  margin-left: 15px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-item-highlight
  .product-item-highlight_info
  .thumbnail {
  margin-bottom: 10px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-item-highlight
  .product-item-highlight_info
  .thumbnail
  a {
  height: 170px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-item-highlight
  .product-item-highlight_info
  .product-txt {
  padding: 0 3px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-item-highlight
  .product-item-highlight_info
  .product-item_info
  li.cart {
  margin-left: auto;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-item-highlight
  .product-item-highlight_info
  .product-item_info
  li.cart
  a {
  border: 1px solid #fe5810;
  width: 24px;
  height: 24px;
  line-height: 26px;
  border-radius: 3px;
  text-align: center;
  display: block;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .product-item-highlight
  .product-item-highlight_info
  .product-item_info
  li.cart
  a:hover {
  background: #fe5810;
  color: #fff;
}

.product-item-wrap .product-items.product-item_proclamation .action-add-new {
  margin-top: 30px;
  border: 1px solid #f6f6f8;
  border-radius: 10px;
  text-align: center;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .action-add-new
  .add-new-title {
  font-size: 13px;
  color: #323643;
  text-transform: uppercase;
  margin: 0;
  padding: 6px 10px;
  letter-spacing: 0.6px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .action-add-new
  .list-action {
  margin: 0;
  background: #f5faff;
  margin: 0;
  padding: 12px 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .action-add-new
  .list-action
  li
  + li {
  margin-left: 12%;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .action-add-new
  .list-action
  li
  a {
  color: #70a1dd;
  font-size: 24px;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .action-add-new
  .list-action
  li
  a:hover {
  color: #fe5810;
}

.product-item-wrap
  .product-items.product-item_proclamation
  .show-product-list
  .product-item_info
  .price {
  color: #70a1dd;
}

.product-item-wrap .product-items .show-product-location .location-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 12px;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-title
  .dropdown {
  margin-right: 10px;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-title
  .dropdown
  .dropdown-toggle {
  color: #70a1dd;
  font-size: 18px;
  text-transform: capitalize;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-title
  .txt-location {
  margin: 0 0 0 auto;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-title
  .txt-location
  [class*="icon-"] {
  color: #70a1dd;
  margin-right: 6px;
  font-size: 16px;
}

.product-item-wrap .product-items .show-product-location .location-list {
  position: relative;
}

.product-item-wrap .product-items .show-product-location .location-list:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 70px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
  left: 0;
  bottom: 0;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item {
  border-top: 1px solid #e6e6ea;
  padding: 9px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-flow: unset;
  -moz-flex-flow: unset;
  -ms-flex-flow: unset;
  flex-flow: unset;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_icon {
  width: 25px;
  margin-right: 8px;
  font-size: 24px;
  color: #70a1dd;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_time {
  width: 56px;
  text-align: right;
  margin-left: 6px;
  white-space: nowrap;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_address {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-flow: unset;
  -moz-flex-flow: unset;
  -ms-flex-flow: unset;
  flex-flow: unset;
  text-transform: capitalize;
  white-space: nowrap;
  max-width: calc(100% - 90px);
  flex: auto;
  width: auto;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_address
  p {
  position: relative;
  margin: 0;
  max-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_address
  .from {
  padding-right: 22px;
  max-width: 92px;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_address
  .from:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 8px;
  background: url("./../assets/images/arrow-right.png") no-repeat center center;
  background-size: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.product-item-wrap
  .product-items
  .show-product-location
  .location-list
  .location-item
  .location-item_address
  .to {
  color: #70a1dd;
}

.product-item-wrap .product-items:not(.product-item_proclamation) {
  padding-top: 20px;
}

.product-item-wrap .product-items:not(.product-item_proclamation):before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background: #fe5810;
  top: 0;
  left: 0;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .product-items_title {
  padding-left: 10px;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item {
  position: relative;
  border: 1px solid #e6e6ea;
  border-radius: 10px;
  background: #fdfdff;
  padding: 10px;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item.product-item-highlight {
  padding: 5px;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item.product-item-highlight
  .thumbnail {
  height: 100%;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item.product-item-highlight
  .thumbnail
  a {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: top center;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item
  .product-item_info
  .cart
  a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item
  .product-item_info
  .cart
  a
  span {
  text-decoration: underline;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item
  .product-item_info
  .cart
  a
  [class*="icon-"] {
  text-decoration: none;
  font-size: 16px;
  margin-right: 5px;
}

.product-item-wrap
  .product-items:not(.product-item_proclamation)
  .show-product-items
  .product-item
  .product-item_info
  .cart
  a:hover
  span {
  text-decoration: none;
}

.product-item-wrap .product-items.product-item_phukmall:before {
  background: #ff8f42;
}

.product-item-wrap
  .product-items.product-item_phukmall
  .product-items_title
  h3 {
  color: #ff8f42;
}

.product-item-wrap .product-items.product-item_hardware:before {
  background: #bc2561;
}

.product-item-wrap
  .product-items.product-item_hardware
  .product-items_title
  h3 {
  color: #bc2561;
}

.product-item-wrap .product-items.product-item_phukplus:before {
  background: #57c07d;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .product-items_title
  h3 {
  color: #57c07d;
}

.product-item-wrap .product-items.product-item_phukplus .map-container {
  border-radius: 10px;
  overflow: hidden;
}

.product-item-wrap .product-items.product-item_phukplus .form-location {
  position: absolute;
  width: calc(100% - 10px);
  height: 70%;
  top: 5px;
  left: 5px;
  background-image: linear-gradient(
    to bottom,
    #ffede5,
    #ffede5,
    rgba(255, 237, 229, 0)
  );
  border-radius: 10px 10px 0 0;
  padding: 20px 15px;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .form-location
  .form-title {
  font-size: 16px;
  color: #323643;
  line-height: 1.4;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .form-location
  .form-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  color: #92929d;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .form-location
  .form-input
  label {
  margin: 0;
  width: 80px;
  padding: 0 5px;
  text-align: center;
  border-left: 1px solid #ececec;
  color: #323643;
  font-size: 13px;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .form-location
  .form-input
  label
  [class*="icon-"] {
  color: #57c07d;
  margin-right: 5px;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .form-location
  .form-input
  .form-control {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
  padding: 10px 8px;
  border: none;
  font-size: 13px;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .show-product-list
  .product-list-item
  .thumbnail {
  width: 65px;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .show-product-list
  .product-list-item
  .thumbnail
  a {
  height: 65px;
}

.product-item-wrap
  .product-items.product-item_phukplus
  .show-product-list
  .product-list-item
  .product-txt {
  width: calc(100% - 75px);
}

.product-item-wrap
  .product-items.product-item_phukplus
  .show-product-list
  .product-list-item
  .product-txt
  h4 {
  margin-bottom: 2px;
}

.modal-login .mail-login {
  padding: 0;
  min-height: 0;
  min-width: 0;
  width: 95%;
  max-width: 435px;
}

.modal-login .mail-login .modal-content {
  background: none;
  border: none;
}

.modal-login .mail-login .modal-content .form-box {
  background: #fff;
}

.modal-login .mail-login .login-wrap .account-question {
  margin-top: 25px;
  font-size: 15px;
}

@media (min-width: 768px) {
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item {
    width: calc((100% - 30px) / 3);
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item
    + .product-item {
    margin-left: 15px;
  }
}

@media (min-width: 992px) {
  .content-wrap .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .content-wrap .container .main-content {
    width: 74%;
    margin-right: 3%;
  }
  .content-wrap .container .sidebar {
    width: 23%;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .product-item-wrap .product-items .product-items_title {
    font-size: 16px;
  }
  .product-item-wrap .product-items .product-items_title h3 {
    font-size: 16px;
  }
  .product-item-wrap .product-items .product-items_title h3 [class*="icon-"] {
    font-size: 20px;
  }
  .product-item-wrap .product-items .product-items_title .category-tag-list li {
    width: auto;
  }
  .product-item-wrap
    .product-items
    .product-items_title
    .category-tag-list
    li
    a {
    font-size: 13px;
  }
  .product-item-wrap .product-items .product-item_info {
    font-size: 12px;
  }
  .product-item-wrap .product-items .show-product-location .location-title {
    margin-bottom: 15px;
  }
  .product-item-wrap
    .product-items
    .show-product-location
    .location-title
    .dropdown
    .dropdown-toggle {
    font-size: 16px;
  }
  .product-item-wrap
    .product-items
    .show-product-list
    .product-list-item
    .product-txt
    h4 {
    font-size: 14px;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item
    .product-item_info
    .cart
    a {
    border: 1px solid #fe5810;
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 3px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item
    .product-item_info
    .cart
    a
    span {
    display: none;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item
    .product-item_info
    .cart
    a
    [class*="icon-"] {
    margin: 0;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .content-wrap {
    padding-bottom: 50px;
  }
  .product-item-wrap .product-items .product-items_title .category-tag-list {
    width: 100%;
    margin-top: 15px;
    overflow-y: auto;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-flow: nowrap;
    -moz-flex-flow: nowrap;
    -ms-flex-flow: nowrap;
    flex-flow: nowrap;
  }
  .product-item-wrap .product-items .product-items_title .category-tag-list li {
    width: auto;
  }
  .product-item-wrap
    .product-items
    .product-items_title
    .category-tag-list
    li
    a {
    font-size: 12px;
  }
  .product-item-wrap
    .product-items
    .product-items_title
    .category-tag-list
    li.show-all {
    margin-left: auto;
  }
  .product-item-wrap .product-items.product-item_proclamation .show-product {
    width: 100%;
  }
  .product-item-wrap
    .product-items.product-item_proclamation
    .show-product:not(.show-product-location) {
    width: 100%;
    margin: 0;
  }
  .product-item-wrap
    .product-items.product-item_proclamation
    .show-product.show-product-location {
    margin-top: 30px;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item.product-item-highlight {
    width: 100%;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item.product-item-highlight
    .thumbnail
    a {
    padding-top: 100%;
  }
}

@media (max-width: 767px) and (min-width: 481px) {
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item.product-item-highlight {
    margin-bottom: 15px;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item:not(.product-item-highlight) {
    width: calc((100% - 15px) / 2);
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item:not(.product-item-highlight)
    + .product-item {
    margin-left: 15px;
  }
}

@media (max-width: 640px) {
  .main-content .news-items .news-item.item-primary .flex-50 {
    width: 100%;
  }
  .main-content .news-items .news-item.item-primary .thumbnail a {
    border-radius: 12px;
    height: auto;
    padding-top: 69.04762%;
    background-size: 100%;
  }
  .main-content .news-items .news-item .item.item-position {
    width: 100%;
    margin-bottom: 15px;
  }
  .main-content .news-items .news-item .item.item-position .thumbnail {
    padding-top: 50.61728%;
    background-size: 100%;
  }
  .main-content .news-items .news-item > .items {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 640px) and (min-width: 481px) {
  .main-content .news-items .news-item .item:not(.item-position) .thumbnail a {
    width: 100px;
    height: 68px;
    background-size: 100%;
  }
  .main-content .news-items .news-item .item:not(.item-position) .box-text {
    width: calc(100% - 115px);
  }
  .main-content .news-items .news-item .item:not(.item-position) .box-text h4 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .main-content
    .news-items
    .news-item
    .item:not(.item-position)
    .box-text
    .link {
    font-size: 12px;
  }
  .main-content .news-items .news-item > .items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .main-content .news-items .news-item > .items .item {
    width: calc((100% - 15px) / 2);
  }
  .main-content .news-items .news-item > .items .item + .item {
    margin: 0 0 0 15px;
  }
  .product-item-wrap .product-items .product-item_info {
    font-size: 12px;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item
    .product-item_info
    .cart
    a
    [class*="icon-"] {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .main-content .news-items .news-item .item {
    width: 100%;
  }
  .main-content .news-items .news-item .item + .item {
    margin: 15px 0 0;
  }
  .product-item-wrap
    .product-items.product-item_proclamation
    .product-items_title {
    padding-right: 0;
  }
  .product-item-wrap
    .product-items.product-item_proclamation
    .show-product-items
    .flex-50 {
    width: 100%;
  }
  .product-item-wrap
    .product-items.product-item_proclamation
    .show-product-items
    .show-product-list {
    margin: 20px 0 0;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item {
    width: 100%;
  }
  .product-item-wrap
    .product-items:not(.product-item_proclamation)
    .show-product-items
    .product-item
    + .product-item {
    margin: 15px 0 0;
  }
  .product-item-wrap .product-items .product-items_title .tabs {
    width: 100%;
    margin-top: 15px;
  }
  .product-item-wrap .product-items .product-items_title .tabs .nav-tabs {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}

@media (max-width: 420px) {
  .modal-login .mail-login .login-wrap .account-question {
    margin-top: 25px;
    font-size: 13px;
  }
}

.mail-wrap .content {
  float: right;
  position: relative;
  z-index: 98;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-info {
  position: sticky;
  top: 0;
  padding-right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  height: 48px;
  border-bottom: 1px solid #f6f6f9;
  z-index: 2;
  background-color: white;
}

.mail-wrap .content .mail-tools {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  min-height: 52px;
  border-bottom: 1px solid #f6f6f9;
  background-color: white;
  z-index: 1;
}

.mail-wrap .content .mail-content-inbox .note {
  position: relative;
  width: calc(100% - 40px);
  margin: 10px 0 0 15px;
  background: #f5f5f7;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  padding: 15px 40px 15px 10px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-content-inbox .note.hide {
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0 0 0 15px;
}

.mail-wrap .content .mail-content-inbox .note a {
  color: #fc5a5a;
}

.mail-wrap .content .mail-content-inbox .note .hide {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  font-size: 20px;
  color: #c0c0c7;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-content-inbox .note .hide:hover {
  cursor: pointer;
  color: #fe5810;
}

.mail-wrap .content .tabs {
  overflow-x: auto;
}

.mail-wrap .content .tabs .nav-tabs {
  -webkit-flex-wrap: unset;
  -moz-flex-wrap: unset;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  white-space: nowrap;
}

.mail-wrap .content .tabs .nav-tabs .nav-link {
  position: relative;
  padding: 12px 20px;
}

.mail-wrap .content .tabs .nav-tabs .nav-link:before {
  content: "";
  display: inline-block;
  margin-right: 16px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d8d8d8;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .tabs .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #fe5810;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  transform: scale(0);
}

.mail-wrap .content .tabs .nav-tabs .nav-link#nav-work-tab:after {
  background: #52c17b;
}
.mail-wrap .content .tabs .nav-tabs .nav-link#nav-social-tab:after {
  background: #00b6ff;
}
.mail-wrap .content .tabs .nav-tabs .nav-link#nav-promotion-tab:after {
  background: #be2060;
}

.tabs .nav-tabs .nav-link#nav-work-tab:hover, .tabs .nav-tabs .nav-link.active#nav-work-tab {
  color: #52c17b;
}
.tabs .nav-tabs .nav-link#nav-social-tab:hover, .tabs .nav-tabs .nav-link.active#nav-social-tab {
  color: #00b6ff;
}
.tabs .nav-tabs .nav-link#nav-promotion-tab:hover, .tabs .nav-tabs .nav-link.active#nav-promotion-tab {
  color: #be2060;
}

.mail-wrap .content .tabs .nav-tabs .nav-link:hover,
.mail-wrap .content .tabs .nav-tabs .nav-link.active {
  background: #fafafb;
}

.mail-wrap .content .tabs .nav-tabs .nav-link:hover:before,
.mail-wrap .content .tabs .nav-tabs .nav-link.active:before {
  background: #fe5810;
}

.mail-wrap .content .tabs .nav-tabs .nav-link:hover:after,
.mail-wrap .content .tabs .nav-tabs .nav-link.active:after {
  transform: scale(1);
}

.mail-wrap .content .add-nav-item {
  background: rgba(254, 88, 16, 0.1);
  display: block;
  text-decoration: none;
  padding: 5px 8px;
  margin-right: 30px;
  color: #fe5810;
  font-weight: bold;
  font-family: rubikMedium, sans-serif;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.mail-wrap .content .add-nav-item:hover {
  background: rgba(254, 88, 16, 0.3);
}

.mail-wrap .content .seclect-mail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 0;
  padding: 20px 35px;
}

.mail-wrap .content .seclect-mail li {
  line-height: 1;
}

.mail-wrap .content .seclect-mail li + li {
  margin-left: 30px;
}

.mail-wrap .content .seclect-mail li a {
  display: block;
  line-height: 1;
}

.mail-wrap .content .seclect-mail li a:hover {
  text-decoration: none;
}

.mail-wrap .content .seclect-mail li.select-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.mail-wrap .content .seclect-mail li.select-checkbox .form-check + .dropdown {
  margin-left: 10px;
}

.mail-wrap
  .content
  .seclect-mail
  li.select-checkbox
  .form-check
  + .dropdown
  > a {
  line-height: 0;
}

.mail-wrap
  .content
  .seclect-mail
  li.select-checkbox
  .form-check
  .form-check-label {
  display: block;
}

.mail-wrap .content .seclect-mail li.select-checkbox .dropdown .dropdown-menu {
  top: 10px !important;
}

.mail-wrap
  .content
  .seclect-mail
  li.select-checkbox
  .dropdown
  .dropdown-menu
  li
  a {
  padding: 8px 10px;
}

.mail-wrap .content .seclect-mail li.select-checkbox.hover_square:before {
  width: 55px;
}

.mail-wrap .content .seclect-mail li.select-checkbox.show:before {
  transform: translate(-50%, -50%) scale(1);
}

.mail-wrap .content .mail-info-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap;
}

.mail-wrap .content .mail-info-left li.total-inbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: #344563;
}

.mail-wrap .content .mail-info-left li.total-inbox .inbox-arrow {
  margin-left: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.mail-wrap .content .mail-info-left li + li {
  margin-left: 16px;
}

.mail-wrap .content .mail-info-left li.total-inbox .inbox-arrow a + a {
  margin-left: 16px;
}

.mail-wrap .content .mail-info .mail-nav {
  display: flex;
  flex-wrap: wrap;
}

.mail-wrap .content .mail-info .mail-grid {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.mail-wrap .content .mail-info .mail-grid p.layout-view {
  margin-bottom: 0;
}

.mail-wrap .content .mail-info .mail-grid .grid-icon {
  margin-left: 16px;
  cursor: pointer;
}

.mail-wrap .content .mail-info .mail-grid .list-grid-icon > path:first-child {
  stroke: #b5b5be;
}

.mail-wrap
  .content
  .mail-info
  .mail-grid
  .list-grid-icon.active
  > path:first-child {
  stroke: #344563;
}

.mail-wrap .content .mail-info .mail-grid .grid-icon > path:last-child {
  fill: #b5b5be;
}

.mail-wrap .content .mail-info .mail-grid .grid-icon.active path:last-child {
  fill: #344563;
}

.mail-wrap .content .mail-items-container {
  height: calc(100vh - 177px);
}

.mail-wrap .content .column-mail-items {
  display: flex;
  height: 100%;
}

.mail-wrap .content .left-column-mail-items {
  min-width: 400px;
  width: 40%;
}

.mail-wrap .content .right-column-mail-items {
  min-width: 400px;
  width: calc(60% - 5px);
}

.mail-wrap .content .column-mail-items .drapdrop-mail-layout-line {
  width: 5px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  cursor: col-resize;
}

.mail-wrap .content .window-mail-items {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mail-wrap .content .top-window-mail-items {
  min-height: 250px;
  height: calc((100vh - 177px) / 2);
  overflow: auto;
}

.mail-wrap .content .bottom-window-mail-items {
  min-height: 250px;
  height: calc(((100vh - 177px) / 2) - 5px);
}

.mail-wrap .content .window-mail-items .drapdrop-mail-layout-line {
  height: 5px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  cursor: row-resize;
}

.mail-wrap .content .mail-info-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap;
  flex-wrap: wrap;
}

.mail-wrap .content .mail-info-right a {
  display: block;
  line-height: 1;
}

.mail-wrap .content .mail-info-right a [class*="icon-"] {
  font-size: 18px;
}

.mail-wrap .content .mail-info-right a.refresh [class*="icon-"] {
  font-size: 20px;
}

.mail-wrap .content .mail-info-right a:hover {
  text-decoration: none;
  color: #fe5810;
}

.mail-wrap .content .mail-info-right li {
  margin-right: 16px;
}

.mail-wrap .content .mail-info-right li.total-inbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: #344563;
}

.mail-wrap .content .mail-info-right li.total-inbox .inbox-arrow {
  margin-left: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.mail-wrap .content .mail-info-right li.total-inbox .inbox-arrow a + a {
  margin-left: 25px;
}

.mail-wrap .content .mail-info-right li.more.dropdown .dropdown-menu {
  left: auto !important;
  right: -5px;
  transform: translate3d(0, 25px, 0px) !important;
}

.mail-wrap .content .mail-info-right li.more.dropdown .dropdown-menu li a {
  padding: 8px 10px;
}

.mail-wrap .content .mail-info-right li.more.show > a {
  color: #fe5810;
}

.mail-wrap .content .mail-info-right li.more.show .hover_circle:before {
  transform: translate(-50%, -50%) scale(1);
}

.mail-wrap .content .txt-edit {
  display: none;
}

.mail-wrap .content .mail-items.mail-items-sent,
.mail-wrap .content .mail-items.mail-items-spam {
  padding-left: 15px;
}

.mail-wrap .content .mail-items .mail-item {
  position: relative;
  padding: 5px 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  /* z-index: 1; */
}

.mail-wrap .content .mail-items .mail-item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2px;
  left: 0;
  background: #f5f5f7;
  z-index: -1;
  transform-origin: center;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  opacity: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.mail-wrap .content .mail-items .mail-item .container-info-mail {
  align-items: center;
  width: auto;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-wrap .content .mail-items .mail-item .hover_circle:before {
  width: 42px;
  height: 42px;
  left: 49%;
}

.mail-wrap .content .mail-items .mail-item .form-check {
  margin-right: 10px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-items .mail-item .form-check label {
  display: block;
}

.mail-wrap .content .mail-items .mail-item .form-check label:hover {
  cursor: pointer;
}

.mail-wrap .content .mail-items .mail-item .mail-star {
  margin-right: 15px;
  font-size: 20px;
  color: #92929d;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-items .mail-item .mail-star.active {
  color: #fe5810;
  text-shadow: 1px 1px 3px #fe5810;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.mail-wrap .content .mail-items .mail-item .mail-trash {
  margin-right: 25px;
  font-size: 22px;
  line-height: 0;
  color: #fc5a5a;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-items .mail-item .mail-item-content {
  margin-right: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: auto;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-wrap .content .mail-items .mail-item .user-send-mail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 210px;
  flex: 0 0 210px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
  color: #344563;
}

.mail-wrap .content .mail-items .mail-item .user-send-mail .avatar {
  width: 28px;
  height: 28px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 15px;
}

.mail-wrap .content .mail-items .mail-item .user-send-mail .user-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.mail-wrap .content .mail-items .mail-item .content-mail-inbox {
  /* white-space: nowrap; */
  overflow: hidden;
  width: auto;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-wrap .content .mail-items .mail-item .content-mail-inbox .mail-title {
  margin-right: 20px;
  color: #344563;
  font-size: 13px;
}

.mail-wrap
  .content
  .mail-items
  .mail-item
  .content-mail-inbox
  .mail-title
  .action-from {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
  background: #dcdce1;
  color: #9696a1;
  border-radius: 5px;
  padding: 3px 10px;
  display: inline-block;
  vertical-align: middle;
}

.mail-wrap
  .content
  .mail-items
  .mail-item
  .content-mail-inbox
  .mail-title-info {
  font-size: 14px;
  font-family: robotoRegular, sans-serif;
}

.mail-wrap .content .mail-items .mail-item .mail-attachment {
  margin: 0 20px 0 0;
  font-size: 20px;
  line-height: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-items .mail-item .mail-inbox-time {
  margin-left: auto;
  white-space: nowrap;
  font-family: robotoRegular, sans-serif;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-wrap .content .mail-items .mail-item:hover,
.mail-wrap .content .mail-items .mail-item.pin {
  cursor: pointer;
}

.mail-wrap .content .mail-items .mail-item:hover:after,
.mail-wrap .content .mail-items .mail-item.pin:after {
  transform: rotateY(0);
  -webkit-transform: rotateY(0);
  opacity: 1;
}

.mail-wrap .content .mail-items .mail-item.item-checked {
  background: #e0f3fb;
}

.mail-wrap .content .mail-items .mail-item.item-checked:after {
  transform: rotateY(0);
  -webkit-transform: rotateY(0);
  opacity: 1;
  /* background: rgba(254, 88, 16, 0.1); */
  background: #e0f3fb;
}

.mail-wrap
  .content
  .mail-items:not(.mail-items-sent)
  .mail-item.pin
  .user-send-mail
  .user-name {
  font-family: rubikMedium, sans-serif;
}

.mail-wrap
  .content
  .mail-items:not(.mail-items-sent)
  .mail-item.pin
  .content-mail-inbox
  .mail-title {
  font-family: rubikMedium, sans-serif;
}

.mail-wrap
  .content
  .mail-items.mail-items-spam
  .mail-item
  .user-send-mail
  .user-name {
  font-family: rubikMedium, sans-serif;
}

.mail-wrap
  .content
  .mail-items.mail-items-spam
  .mail-item
  .content-mail-inbox
  .mail-title {
  font-family: rubikMedium, sans-serif;
}

.mail-wrap.has-sidebar-right .sidebar-right {
  width: 300px;
  right: 0;
  left: auto;
  border-left: 1px solid #eeeff3;
  border-right: 0;
}

.mail-wrap.has-sidebar-right .content {
  width: calc(100% - 530px);
}

@media (min-width: 1301px) {
  .mail-wrap .content .tabs .nav-tabs .nav-link {
    padding: 12px 32px;
  }
}

@media (max-width: 1300px) and (min-width: 768px) {
  .mail-wrap .content .tabs .nav-tabs .nav-link {
    padding: 12px 25px;
  }
  .mail-wrap .content .mail-info-right li {
    margin-right: 16px;
  }
  .mail-wrap .content .mail-info-right li.total-inbox .inbox-arrow a + a {
    margin-left: 16px;
  }
}

@media (max-width: 991px) {
  .mail-wrap {
    font-size: 14px;
  }
  .mail-wrap .content .tabs {
    max-width: calc(100% - 30px);
  }
  .mail-wrap .content .mail-info:not(.mail-check-all) {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    -webkit-justify-content: unset;
    -moz-justify-content: unset;
    justify-content: unset;
  }
  .mail-wrap .content .mail-info:not(.mail-check-all) .mail-info-right {
    width: 100%;
    border-top: 1px solid #f6f6f9;
    padding: 15px 0 15px 20px;
  }
  .mail-wrap .content .seclect-mail {
    padding-left: 15px;
  }
  .mail-wrap .content .add-nav-item {
    margin: 0;
  }
  .mail-wrap .content .mail-items .mail-item .form-check {
    margin-right: 10px;
  }
  .mail-wrap .content .mail-items .mail-item .mail-star,
  .mail-wrap .content .mail-items .mail-item .mail-item-content,
  .mail-wrap .content .mail-items .mail-item .user-send-mail,
  .mail-wrap .content .mail-items .mail-item .mail-attachment {
    margin-right: 15px;
  }
  .mail-wrap .content .mail-items .mail-item .form-check-label:after {
    width: 20px;
    height: 20px;
  }
  .mail-wrap .content .mail-items .mail-item .form-check-label:before {
    left: 4px;
  }
  .mail-wrap .content .mail-items .mail-item .user-send-mail {
    width: 180px;
    flex: 0 0 180px;
  }
  .mail-wrap .content .mail-content-inbox .note {
    width: calc(100% - 25px);
  }
}

@media (max-width: 767px) {
  .mail-wrap .sidebar {
    top: 63px;
    height: calc(100% - 63px);
  }
  .mail-wrap .content .mail-info {
    padding-right: 20px;
  }
  .mail-wrap .content .mail-info .mail-info-right {
    padding-right: 15px;
  }
  .mail-wrap .content .mail-items .mail-item {
    padding: 10px 12px;
  }
  .mail-wrap .content .mail-items .mail-item:after {
    height: calc(100% - 15px);
    top: 8px;
  }
  .mail-wrap .content .mail-items .mail-item .mail-item-content {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    width: 100%;
  }
  .mail-wrap
    .content
    .mail-items
    .mail-item
    .mail-item-content:not(.no-avatar)
    .content-mail-inbox {
    padding-left: 45px;
  }
  .mail-wrap .content .mail-items .mail-item .user-send-mail {
    margin: 0;
  }
  .mail-wrap .content .mail-items .mail-item .user-send-mail .avatar {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    transform: translateY(10px);
  }
  .mail-wrap .content .mail-items .mail-item .content-mail-inbox {
    width: 100%;
  }
  .mail-wrap .content .mail-items .mail-item .content-mail-inbox .mail-title {
    margin-right: 10px;
    font-size: 13px;
  }
  .mail-wrap
    .content
    .mail-items
    .mail-item
    .content-mail-inbox
    .mail-title
    .action-from {
    font-size: 11px;
    padding: 3px 5px 1px;
  }
  .mail-wrap
    .content
    .mail-items
    .mail-item
    .content-mail-inbox
    .mail-title-info {
    font-size: 13px;
  }
}

@media (max-width: 640px) {
  .mail-wrap .content .tabs .nav-tabs .nav-link {
    padding: 12px 15px;
  }
}

@media (max-width: 480px) {
  .mail-wrap .content .mail-info .txt-edit {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: #fe5810;
  }
  .mail-wrap .content .mail-info.mail-check-all {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    -webkit-justify-content: unset;
    -moz-justify-content: unset;
    justify-content: unset;
  }
  .mail-wrap .content .mail-info.mail-check-all .mail-info-right {
    width: 100%;
    border-top: 1px solid #f6f6f9;
    padding: 15px 0 15px 20px;
    padding-right: 60px;
  }
  .mail-wrap .content .mail-items .mail-item .hover_circle:before {
    width: 38px;
    height: 38px;
  }
  .mail-wrap .content .mail-items .mail-item .form-check.hover_circle:before {
    left: 42%;
  }
  .mail-wrap .content .mail-items .mail-item .mail-attachment {
    font-size: 18px;
  }
  .mail-wrap .content .mail-items:not(.edit) .mail-item .form-check,
  .mail-wrap .content .mail-items:not(.edit) .mail-item .mail-star,
  .mail-wrap .content .mail-items:not(.edit) .mail-item .mail-trash {
    width: 0;
    transform: scale(0);
    visibility: hidden;
    margin: 0;
    opacity: 0;
  }
  .mail-wrap .content .mail-items.edit .mail-item .mail-attachment {
    margin-right: 0;
  }
  .mail-wrap .content .mail-items.edit .mail-item .mail-inbox-time {
    width: 0;
    transform: scale(0);
    visibility: hidden;
    margin: 0;
    opacity: 0;
  }
}

@media (max-width: 400px) {
  .mail-wrap {
    font-size: 12px;
  }
  .mail-wrap .mail-detail-content {
    font-size: 14px;
  }
  .mail-wrap .content .mail-info .mail-info-right {
    padding-left: 10px;
  }
  .mail-wrap .content .mail-info .mail-info-right li + li {
    margin-left: 15px;
  }
  .mail-wrap .content .mail-info .mail-info-right a [class*="icon-"] {
    font-size: 15px;
  }
  .mail-wrap .content .mail-info .mail-info-right a.refresh [class*="icon-"] {
    font-size: 17px;
  }
  .mail-wrap .content .mail-items .mail-item .mail-item-content {
    line-height: 1.2;
  }
  .mail-wrap
    .content
    .mail-items
    .mail-item
    .mail-item-content:not(.no-avatar)
    .content-mail-inbox {
    transform: translateY(-5px);
  }
  .mail-wrap
    .content
    .mail-items
    .mail-item
    .mail-item-content.no-avatar
    .content-mail-inbox {
    margin-top: 5px;
  }
  .mail-wrap .content .mail-items .mail-item .content-mail-inbox .mail-title {
    font-size: 12px;
  }
  .mail-wrap .content .seclect-mail li a {
    font-size: 18px;
  }
}

.modal-new-message {
  color: #777781;
}

.modal-new-message .modal-dialog {
  max-width: 1200px;
  width: 95%;
}

.modal-new-message .modal-content {
  border-radius: 15px;
  overflow: hidden;
  border: none;
}

.modal-new-message .modal-content .modal-header {
  background: #fafafb;
  position: relative;
  padding: 24px 80px 24px 20px;
}

.modal-new-message .modal-content .modal-header h3 {
  text-transform: capitalize;
  font-size: 20px;
  color: #696974;
}

.modal-new-message .modal-content .modal-header .close {
  font-size: 15px;
  margin: 0;
  color: #fe5810;
  font-weight: normal;
  padding: 0;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.modal-new-message .form-to-account .form-group {
  padding: 0 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.modal-new-message .form-to-account .form-group + .txt-message {
  margin-top: 30px;
}

.modal-new-message .form-to-account .form-group + .form-attach-document {
  margin-top: 45px;
}

.modal-new-message .form-to-account .form-group [class*="col-"] {
  padding: 0;
}

.modal-new-message .form-to-account .form-group label {
  color: #bbbbc4;
  flex: 0 0 70px;
  max-width: 70px;
  margin-right: 10px;
}

.modal-new-message .form-to-account .form-group .col-sm-10 {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
  border-bottom: 1px solid #e2e2ea;
}

.modal-new-message .form-to-account .form-group .mail-sendto {
  padding-right: 80px;
}

.modal-new-message .form-to-account .form-group .mail-sendto .more-send {
  position: absolute;
  margin: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.modal-new-message .form-to-account .form-group .mail-sendto .more-send li {
  display: inline-block;
  vertical-align: middle;
}

.modal-new-message
  .form-to-account
  .form-group
  .mail-sendto
  .more-send
  li
  + li {
  margin-left: 18px;
}

.modal-new-message
  .form-to-account
  .form-group
  .mail-sendto
  .more-send
  li
  a:hover {
  color: #fe5810;
  text-decoration: none;
}

.modal-new-message .form-to-account .form-group .form-control {
  border: none;
  border-radius: 0;
  padding: 10px 0;
}

.modal-new-message .form-to-account .form-group textarea {
  resize: none;
}

.modal-new-message .form-to-account .select2-container {
  width: 100% !important;
  border: none;
  padding-bottom: 5px;
}

.modal-new-message
  .form-to-account
  .select2-container
  .select2-selection--multiple {
  border: none;
  border-radius: 0;
}

.modal-new-message
  .form-to-account
  .select2-container
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0;
}

.modal-new-message
  .form-to-account
  .select2-container
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  background: none;
  padding: 3px 25px 5px 10px;
  border-radius: 10px;
  margin-right: 10px;
  position: relative;
  border-color: #d5d5dc;
}

.modal-new-message
  .form-to-account
  .select2-container
  .select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-selection__choice__remove {
  position: absolute;
  display: block;
  font-size: 20px;
  color: #92929d;
  top: 48%;
  transform: translateY(-50%);
  right: 5px;
  margin: 0;
}

.modal-new-message
  .form-to-account
  .select2-container
  .select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-selection__choice__remove:hover {
  color: #fe5810;
}

.modal-new-message .modal-footer {
  padding: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.mail-action-send {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.mail-action-send .modal-button {
  margin-right: 20px;
}

.mail-action-send .modal-button .btn {
  padding: 8px 10px;
  border-radius: 10px;
  min-width: 120px;
  font-size: 14px;
}

@media (max-width: 675px) {
  .modal-new-message .form-to-account {
    font-size: 12px;
  }
  .modal-new-message .form-to-account .form-group .mail-sendto {
    padding-right: 60px;
  }
  .modal-new-message
    .form-to-account
    .form-group
    .mail-sendto
    .more-send
    li
    + li {
    margin-left: 10px;
  }
  .modal-new-message .form-to-account .form-group .form-control {
    font-size: 13px;
  }
  .modal-new-message
    .form-to-account
    .form-group
    .document-list
    li
    a
    .document-title {
    font-size: 12px;
  }
  .modal-new-message .form-to-account .form-group.form-attach-document {
    padding: 0;
  }
  .modal-new-message .mail-action-send {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .modal-new-message .mail-action-send .modal-button {
    order: 2;
    margin: 0;
  }
  .modal-new-message .mail-action-send .list-action-attachment {
    order: 1;
    width: 100%;
    margin-bottom: 15px;
  }
  .modal-new-message
    .mail-action-send
    .list-action-attachment
    li
    [class*="icon-"] {
    font-size: 18px;
  }
  .modal-new-message .modal-footer-right .list-action-more li + li {
    margin-left: 15px;
  }
  .modal-new-message .modal-footer-right .list-action-more li a {
    font-size: 14px;
  }
  .modal-new-message .modal-footer-right .list-action-more li [class*="icon-"] {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .modal-new-message .form-to-account .form-group {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .modal-new-message .form-to-account .form-group label {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 10px;
    font-size: 14px;
  }
  .modal-new-message .form-to-account .form-group .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .modal-new-message .form-to-account .form-group .mail-sendto {
    padding-right: 0;
  }
  .modal-new-message .form-to-account .form-group .mail-sendto .more-send {
    top: -28px;
    transform: translateY(0);
  }
  .modal-new-message .modal-footer {
    position: relative;
  }
  .modal-new-message .modal-footer-right {
    position: absolute;
    bottom: 30px;
    right: 20px;
  }
}

@media (max-width: 400px) {
  .modal-new-message .form-to-account {
    font-size: 11px;
  }
}

.mail-content-inbox {
  position: relative;
}

.mail-content-inbox .mail-detail .mail-detail-reply {
  display: none;
}

.mail-content-inbox .mail-widget {
  background: #fff;
  display: none;
}

.mail-content-inbox.mail-content-reply {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.mail-content-inbox.mail-content-reply .tab-content {
  width: calc(100% - 300px);
}

.mail-content-inbox.mail-content-reply .mail-detail .mail-detail-action {
  display: none;
}

.mail-content-inbox.mail-content-reply .mail-detail .mail-detail-reply {
  display: block;
}

.mail-content-inbox.mail-content-reply .mail-widget {
  width: 300px;
  display: block;
}

.mail-detail .mail-detail-header {
  padding: 25px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid #eeeff3;
  color: #92929d;
}

.mail-detail .mail-detail-header a {
  color: #b5b5be;
}

.mail-detail .mail-detail-header a:hover {
  color: #fe5810;
}

.mail-detail .mail-detail-header p {
  margin: 0 20px 0 0;
}

.mail-detail .mail-detail-item {
  padding: 25px 20px 35px;
}

.mail-detail .mail-detail-item .mail-detail-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 30px;
}

.mail-detail .mail-detail-item .mail-detail-title h2 {
  margin: 0 20px 7px 0;
}

.mail-detail .mail-detail-item .mail-detail-title p {
  margin: 0;
  line-height: 1;
}

.mail-detail .mail-detail-item .mail-detail-title .mail-d-subject {
  align-items: center;
  width: auto;
  /* flex: auto; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-detail .mail-detail-item .mail-d-body {
  word-break: break-all;
}

.mail-detail .mail-detail-item .mail-detail-title p.set-important {
  font-size: 17px;
  color: #d5d5dc;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-detail .mail-detail-item .mail-detail-title p.hover_circle {
  margin-right: 20px;
}

.mail-detail .mail-detail-item .mail-detail-title p a {
  background: #f1f1f5;
  padding: 2px 10px 3px 7px;
  border-radius: 3px;
  position: relative;
  font-size: 14px;
}

.mail-detail .mail-detail-item .mail-detail-title p a:after {
  content: "x";
  display: inline-block;
  margin-left: 10px;
}

.mail-detail .mail-detail-item .mail-detail-title p a:hover {
  text-decoration: none;
  color: #696974;
  background: rgba(254, 88, 16, 0.1);
}

.mail-detail .mail-detail-item .mail-detail-title p a:hover:after {
  color: #fe5810;
}

.mail-detail .mail-detail-item .user {
  margin-bottom: 35px;
}

.mail-detail .mail-detail-item .mail-detail-content {
  color: #6f6f79;
}

.mail-detail .mail-detail-item .mail-detail-content p:not(:last-child) {
  margin-bottom: 25px;
}

.mail-detail .mail-detail-item .mail-detail-content figure + p {
  margin-top: 25px;
}

.mail-detail .mail-detail-item .mail-detail-content .mail-detail-image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 745px;
  padding-top: 37.84431%;
  margin: 0;
}

.mail-detail .mail-detail-action {
  padding: 10px 20px;
  margin-bottom: 35px;
}

.mail-detail .mail-detail-action li {
  display: inline-block;
  vertical-align: middle;
}

.mail-detail .mail-detail-action li + li {
  margin-left: 12px;
}

.mail-detail .mail-detail-action li a {
  display: block;
  border: 1px solid #d5d5dc;
  color: #696974;
  border-radius: 10px;
  overflow: hidden;
  padding: 9px 20px 9px 10px;
  position: relative;
  z-index: 1;
}

.mail-detail .mail-detail-action li a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(254, 88, 16, 0.1);
  left: 0;
  top: 0;
  z-index: -1;
  transform: scale(0);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mail-detail .mail-detail-action li a [class*="icon-"] {
  margin-right: 14px;
  font-size: 18px;
  display: inline-block;
  vertical-align: text-bottom;
  padding-bottom: 1px;
}

.mail-detail .mail-detail-action li a:hover {
  border-color: rgba(254, 88, 16, 0.1);
  color: #fe5810;
  text-decoration: none;
}

.mail-detail .mail-detail-action li a:hover:after {
  transform: scale(1);
}

.mail-detail .mail-detail-reply {
  border-top: 1px solid #eeeff3;
}

.mail-detail .mail-detail-reply .detail-reply-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeff3;
}

.mail-detail .mail-detail-reply .detail-reply-header .user {
  margin-right: 20px;
}

.mail-detail
  .mail-detail-reply
  .detail-reply-header
  .user
  .user-info
  p.user-info {
  color: #44444f;
}

.mail-detail .mail-detail-reply .detail-reply-header .user .reply-to {
  margin-right: 20px;
  color: #92929d;
  font-size: 20px;
}

.mail-detail .mail-detail-reply .detail-reply-content {
  padding: 15px 20px;
}

.mail-detail .mail-detail-reply .detail-reply-content textarea {
  padding: 0;
  resize: none;
  border: none;
  border-radius: 0;
}

.mail-detail .mail-detail-reply .mail-action-send {
  border-top: 1px solid #eeeff3;
  padding: 10px 20px;
  color: #6f6f79;
}

.mail-widget {
  border-left: 1px solid #f1f1f5;
}

.mail-widget .show-mail-widget {
  display: none;
}

.mail-widget .widget-item {
  position: relative;
  padding: 25px 20px;
}

.mail-widget .widget-item + .widget-item:before {
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  top: -1px;
  border-top: 1px solid #f1f1f5;
}

.mail-widget .widget-action {
  border-bottom: 1px solid #f1f1f5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.mail-widget .widget-action .list-action-more .calendar {
  color: #4e6cfb;
}

.mail-widget .widget-action .list-action-more .security {
  color: #82c43c;
}

.mail-widget .widget-action .list-action-more .emoji {
  color: #ffa459;
}

.mail-widget .widget-action .add-widget a {
  color: #fe5810;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
}

.mail-widget .widget-action .add-widget a:before {
  content: "+";
  display: inline-block;
  margin-right: 5px;
  font-size: 16px;
}

.mail-widget .document-group .document-list li:nth-child(2n) {
  margin-right: 0;
}

.group-title {
  font-size: 18px;
  color: #171725;
  margin-bottom: 15px;
}

.media-group .media-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.media-group .media-list .list {
  width: calc((100% - 10px) / 2);
}

.media-group .media-list .list + .list {
  margin-left: 10px;
}

.media-group .media-list .list li {
  border-radius: 15px;
  overflow: hidden;
}

.media-group .media-list .list li + li {
  margin-top: 10px;
}

.media-group .media-list .list li a {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-size: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;
}

.media-group .media-list .list li a.h_95 {
  padding-top: 76%;
}

.media-group .media-list .list li a.h_135 {
  padding-top: 108%;
}

.media-group .media-list .list li a:hover {
  transform: scale(1.1);
}

.document-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.document-list li {
  width: calc((100% - 15px) / 2);
  max-width: 120px;
  margin-bottom: 15px;
}

.document-list li:not(:last-child) {
  margin-right: 15px;
}

.document-list li a {
  display: block;
  background: #fff9ec;
  color: #344563;
  border-radius: 15px;
  padding: 15px;
  height: 100%;
}

.document-list li a span {
  display: block;
}

.document-list li a [class*="icon-"] {
  font-size: 24px;
  color: #ffc542;
  margin-bottom: 15px;
}

.document-list li a .document-title {
  font-size: 14px;
  font-family: rubikMedium, sans-serif;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.document-list li a .document-size {
  color: #92929d;
  font-size: 13px;
  font-family: robotoRegular, sans-serif;
}

.document-list li a:hover {
  background: rgba(254, 88, 16, 0.1);
  text-decoration: none;
}

@media (max-width: 991px) {
  .mail-content-inbox {
    overflow: hidden;
  }
  .mail-content-inbox.mail-content-reply .tab-content {
    width: 100%;
  }
  .mail-content-inbox.mail-content-reply .mail-widget {
    position: absolute;
    top: 0;
    right: -300px;
    height: 100%;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .mail-content-inbox.mail-content-reply .mail-widget .mail-widget-content {
    height: 100%;
    overflow-y: auto;
  }
  .mail-content-inbox.mail-content-reply .mail-widget .show-mail-widget {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    background: url("./../assets/images/touch_arrow.png") no-repeat center
        center/70%,
      #fff;
    left: -30px;
    top: 58px;
    -moz-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
    -webkit-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
    -ms-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
    -o-box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
    box-shadow: 0 0 4px 0 rgba(254, 88, 16, 0.4);
  }
  .mail-content-inbox.mail-content-reply .mail-widget.show {
    right: 0;
  }
  .mail-content-inbox.mail-content-reply .mail-widget.show .show-mail-widget {
    transform: rotate(180deg);
  }
}

@media (max-width: 565px) {
  .mail-detail .mail-detail-item .mail-detail-title h2 {
    font-size: 18px;
  }
  .mail-detail .mail-detail-action li a {
    font-size: 13px;
    padding: 6px 10px 6px 10px;
  }
  .mail-detail .mail-detail-action li a [class*="icon-"] {
    font-size: 15px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .mail-action-send {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .mail-action-send .modal-button {
    order: 2;
    width: 100%;
    margin: 20px 0 0;
  }
  .mail-action-send .list-action-attachment {
    order: 1;
    width: 100%;
  }
}

@media (max-width: 420px) {
  .mail-content-inbox.mail-content-reply .mail-widget {
    width: 230px;
    right: -230px;
  }
  .mail-widget .widget-item {
    padding: 15px 10px;
  }
  .mail-widget .list-action-more li [class*="icon-"] {
    font-size: 18px;
  }
  .mail-widget .widget-action .add-widget a {
    font-size: 13px;
  }
  .mail-widget .document-list li {
    width: calc((100% - 10px) / 2);
  }
  .mail-widget .document-list li:not(:nth-child(2n)) {
    margin-right: 10px;
  }
  .mail-widget .document-list li a {
    padding: 15px 10px;
  }
  .mail-widget .document-list li a .document-title {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .mail-detail .mail-detail-header {
    padding: 15px 10px;
  }
  .mail-detail .mail-detail-header li + li {
    margin-left: 15px;
  }
  .mail-detail .mail-detail-header a [class*="icon-"] {
    font-size: 16px;
  }
  .mail-detail .mail-detail-header a.hover_square:before {
    width: 30px;
    height: 30px;
  }
  .mail-detail .mail-detail-item {
    padding: 15px 10px 20px;
  }
  .mail-detail .mail-detail-action {
    padding: 10px;
  }
  .mail-detail .mail-detail-action li + li {
    margin-left: 5px;
  }
  .mail-detail .mail-detail-action li a {
    font-size: 12px;
    padding: 5px 8px;
  }
  .mail-detail .mail-detail-action li a [class*="icon-"] {
    font-size: 13px;
    margin-right: 5px;
  }
  .mail-detail .mail-detail-reply .detail-reply-header {
    padding: 10px;
  }
  .mail-detail
    .mail-detail-reply
    .detail-reply-header
    .list-action-more
    li
    + li {
    margin-left: 10px;
  }
  .mail-detail
    .mail-detail-reply
    .detail-reply-header
    .list-action-more
    li
    [class*="icon-"] {
    font-size: 16px;
  }
  .mail-detail .mail-detail-reply .detail-reply-content textarea {
    font-size: 14px;
  }
  .mail-detail .mail-detail-reply .mail-action-send {
    padding: 10px;
  }
  .mail-detail
    .mail-detail-reply
    .mail-action-send
    .list-action-attachment
    li
    [class*="icon-"] {
    font-size: 18px;
  }
}

.mail-login {
  padding: 50px 15px;
  min-height: calc(100vh - 77px - 89px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.mail-login .form-title {
  text-transform: capitalize;
  font-size: 24px;
}

.mail-login .login-wrap {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.mail-login .login-wrap:not(.no-banner) {
  border: 1px solid #f1f1f5;
  -moz-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  -webkit-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  -ms-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  -o-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  border-radius: 15px;
  overflow: hidden;
}

.mail-login .login-wrap .box-image .image-banner {
  height: 100%;
  background-size: 100% 100%;
}

.mail-login .login-wrap .form-box {
  padding: 40px 5%;
}

.mail-login .login-wrap .login-form {
  margin: 25px 0;
}

.mail-login .login-wrap .login-form .form-group {
  margin-bottom: 15px;
}

.mail-login .login-wrap .login-form .form-group + .btn {
  margin-top: 10px;
}

.mail-login .login-wrap .login-form .form-control {
  height: 50px;
  border: none;
  background: #f5f5f7;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 14px;
}

.mail-login .login-wrap .login-form .form-control::placeholder {
  color: #b5b5be;
}

.mail-login .login-wrap .login-form .btn {
  width: 100%;
  height: 50px;
  border-radius: 15px;
}

.mail-login .login-wrap .login-form .mail-value {
  position: relative;
}

.mail-login .login-wrap .login-form .mail-value .form-control {
  padding-right: 140px;
}

.mail-login .login-wrap .login-form .mail-value .mail-holder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: #323643;
  font-size: 14px;
}

.mail-login .login-wrap .login-form .form-check .form-check-label {
  color: #92929d;
  font-size: 14px;
}

.mail-login .login-wrap .login-form .form-check .form-check-label:after {
  width: 15px;
  height: 15px;
  border-color: #92929d;
}

.mail-login .login-wrap .login-form .form-check .form-check-label:before {
  font-size: 12px;
  left: 3px;
}

.mail-login
  .login-wrap
  .login-form
  .form-check
  .form-check-input:checked
  + .form-check-label:after {
  border-color: #fe5810;
}

.mail-login .login-wrap .account-question {
  text-align: center;
  width: 100%;
  color: #92929d;
}

.mail-login .login-wrap .account-question a {
  color: #fe5810;
  text-decoration: underline;
  font-weight: bold;
}

.mail-login .login-wrap .account-question a:hover {
  text-decoration: none;
}

.mail-login .login-wrap.no-banner {
  max-width: 435px;
}

.mail-login .login-wrap.no-banner .form-box {
  width: 100%;
  padding: 40px 30px;
  border: 1px solid #f1f1f5;
  -moz-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  -webkit-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  -ms-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  -o-box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  box-shadow: 0px 0px 40px 0px rgba(184, 184, 199, 0.5);
  border-radius: 15px;
  overflow: hidden;
}

.mail-login .login-wrap.no-banner .form-box .form-title,
.mail-login .login-wrap.no-banner .form-box .txt-meta {
  padding-left: 10px;
}

.mail-login .login-wrap.no-banner .form-box .login-form {
  margin-bottom: 0;
}

.mail-login .login-wrap.no-banner .form-box + .account-question {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .mail-login .login-wrap {
    position: relative;
  }
  .mail-login .login-wrap:not(.no-banner) {
    max-width: 475px;
  }
  .mail-login .login-wrap:not(.no-banner) .box-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .mail-login .login-wrap:not(.no-banner) .box-image .image-banner:after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .mail-login .login-wrap:not(.no-banner) .form-box {
    position: relative;
    width: 100%;
    z-index: 3;
  }
  .mail-login .login-wrap:not(.no-banner) .form-box .txt-meta,
  .mail-login .login-wrap:not(.no-banner) .form-box .account-question {
    color: #344563;
  }
  .mail-login .login-wrap:not(.no-banner) .login-form .form-control {
    background: rgba(146, 146, 157, 0.8);
    color: #fff;
  }
  .mail-login
    .login-wrap:not(.no-banner)
    .login-form
    .form-control::placeholder {
    color: #fff;
  }
}

@media (max-width: 420px) {
  .mail-login {
    padding: 50px 10px;
  }
  .mail-login .login-wrap.no-banner .form-box {
    padding: 40px 5%;
  }
  .mail-login .login-wrap .login-form .form-control {
    padding: 10px;
  }
}

#nav-work-tab::before {
  background: #52c17b;
}

#nav-social-tab::before {
  background: #00b6ff;
}

#nav-promotion-tab::before {
  background: #be2060;
}

.category-tag {
  color: white;
  padding: 4px;
  border-radius: 3px;
  font-size: 9px;
  font-weight: 700;
}

.detail-content-default .detail-h {
  text-align: center;
  padding: 40px;
  font-size: 16px;
  color: #202124;
}

.detail-content-default .detail-b {
  text-align: center;
  color: #666;
  font-size: 12px;
}
