.dot {
  background-color: #FF2525;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
}
.groupTitle {
  color: #323643;
  font-weight: 600;
  font-size: 14px;
  flex: 3
}
.videoGroup {
  position: relative;
  height: 100%;
  display: inline-block;
  width: 420px;
  flex: 2;
  cursor: pointer;
}

.videoGroup img {
  width: 100%;
}

@media (max-width: 992px) {
  .videoGroup {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.title {
  font-size: 30px;
  font-weight: 900;
  margin: 20px 0;
}
.groupVideoInfo {
  display: block;
}

.emailSupport {
  border: 1px solid rgba(151, 151, 151, 0.15);
  padding: 5px 8px;
  background: #FAFAFF;
  color: #d8d8d8;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: lighter;
}
.groupSupportInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .groupVideoInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
  }
}
.extraText {
  font-weight: lighter;
  padding-bottom: 10px;
}

.extraText a {
  color: #FF570D;
  text-decoration: underline;
}

.videoGroup .backgroundVideo {
  border-radius: 20px;
}

.videoGroup .playButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); 
  z-index: 2;
  background: none;
  border: none
}

.videoGroup .duration {
  position: absolute;
  background-color: #344563;
  border-radius: 6px;
  padding: 4px 8px;
  color: white;
  z-index: 3;
  bottom: 10px;
  right: 10px;
  font-size: 13px;
}